import { ENDPOINTS } from '../common/endpoints';
import { IRequiredItems } from '../models/Items/IRequiredItems';
import { ICloseOrder } from '../models/Order/ICloseOrder';
import { IGroupedOrdersByDay } from '../models/Order/IGroupedOrdersByDay';
import { IOrder } from '../models/Order/IOrder';
import { IOrderSearch } from '../models/Order/IOrderSearch';
import { baseAPI } from './BaseAPI';

export const orderAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllOrders: build.query<IGroupedOrdersByDay[], void>({
            query: () => ({
                url: ENDPOINTS.ORDERS.GET_ALL_ORDERS,
                method: 'GET',
            }),

            providesTags: (result) => ['Order'],
        }),

        saveOrder: build.mutation<IOrder, IOrder>({
            query: (order: IOrder) => ({
                url: ENDPOINTS.ORDERS.SAVE_ORDER,
                method: 'POST',
                body: order,
            }),

            invalidatesTags: (result) => ['Order', { type: 'Order', id: 'Week' }, { type: 'Item', id: 'RequiredItems' }],
        }),

        changeOrderStage: build.mutation<IOrder, number>({
            query: (id: number) => ({
                url: `${ENDPOINTS.ORDERS.CHANGE_ORDER_STAGE}${id}`,
                method: 'POST',
            }),

            invalidatesTags: (result) => ['Order', 'Item', { type: 'Order', id: 'Week' }, { type: 'Item', id: 'RequiredItems' }],
        }),

        closeOrder: build.mutation<IOrder, ICloseOrder>({
            query: (dto: ICloseOrder) => ({
                url: ENDPOINTS.ORDERS.CLOSE_ORDER,
                method: 'POST',
                params: { id: dto.id, isReceiptIssued: dto.isReceiptIssued },
            }),

            invalidatesTags: (result) => ['Order', { type: 'Order', id: 'Week' }, { type: 'Item', id: 'RequiredItems' }],
        }),

        getOrderSeveralDayAhead: build.query<IGroupedOrdersByDay[], void>({
            query: () => ({
                url: ENDPOINTS.ORDERS.GET_ORDERS_SEVERAL_DAY_AHEAD,
                method: 'GET',
            }),

            providesTags: (result) => [
                { type: 'Order', id: 'Week' },
                { type: 'Item', id: 'RequiredItems' },
            ],
        }),

        getRequiredItemsByOrderIds: build.query<IRequiredItems[], number[]>({
            query: (ids: number[]) => ({
                url: ENDPOINTS.ORDERS.GET_REQUIRED_ITEMS_BY_ORDER_IDS,
                method: 'GET',
                params: { ids: ids },
            }),

            providesTags: [{ type: 'Item', id: 'RequiredItems' }],
        }),

        deleteOrder: build.mutation<void, number>({
            query: (id: number) => ({
                url: ENDPOINTS.ORDERS.DELETE_ORDER,
                method: 'DELETE',
                params: { id: id },
            }),

            invalidatesTags: (result) => ['Order', { type: 'Order', id: 'Week' }, { type: 'Item', id: 'RequiredItems' }],
        }),

        getOrderById: build.mutation<IGroupedOrdersByDay[], number | string>({
            query: (id: number | string) => ({
                url: ENDPOINTS.ORDERS.GET_ORDER_BY_ID,
                method: 'GET',
                params: { id: id },
            }),

            invalidatesTags: (result) => [{ type: 'Order', id: 'ById' }],
        }),

        orderSearch: build.mutation<IGroupedOrdersByDay[], IOrderSearch>({
            query: (data: IOrderSearch) => ({
                url: ENDPOINTS.ORDERS.ORDER_SEARCH,
                method: 'GET',
                params: { query: data.query, closedOrders: data.closedOrders, isDateRange: data.isDateRange, isMultipleDate: data.isMultipleDate },
            }),

            invalidatesTags: (result) => [{ type: 'Order', id: 'search' }],
        }),
    }),
});
