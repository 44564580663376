import { Box, Tabs, Tab } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';

import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import Recipes from './Recipes';
import Fillings from './Fillings';
import Items from '../items/Items';

function RecipesMain() {
    const { language } = useLanguage();
    const history = useNavigate();
    const location = useLocation();

    const handleChange = (event: any, newValue: string) => {
        history(newValue);
    };

    const tabStyle = {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderRightWidth: 'thin',
        height: 'auto',
        alignSelf: 'stretch',
        margin: 0,
        flexShrink: 0,
    };

    const fontSize = { fontSize: '18px' };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '10px' }}>
                <Tabs value={location.pathname} onChange={handleChange}>
                    <Tab label={getTitle(language, 'recipes')} value="/recipes" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'recipes_fillings')} value="/recipes/fillings" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'recipes_decorations')} value="/recipes/decorations" sx={{ ...fontSize }} />
                </Tabs>
            </Box>

            <Routes>
                <Route path="/" element={<Recipes />} />
                <Route path="fillings" element={<Fillings />} />
                <Route path="decorations" element={<></>} />
            </Routes>
        </Box>
    );
}

export default RecipesMain;
