import { useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';
import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import EditSubOrder from '../editOrder/EditSubOrder';
import getTitle from '../../../common/translations';
import { FIELDS } from '../../../common/dictionary';

interface Props {
    existSubOrder: ISubOrder | undefined;
    editedSubOrder: ISubOrder;
    recipes?: IRecipe[] | undefined;
    handleOrderPrice?: () => void;
    deleteSubOrder: (subOrderId: number | string) => void;
    saveSubOrderChanges: (subOrder: ISubOrder) => void;
}

const EditSubOrderHOC = ({ existSubOrder, editedSubOrder, recipes, handleOrderPrice, deleteSubOrder, saveSubOrderChanges }: Props) => {
    const { language } = useLanguage();
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();
    const [editMode, setEditMode] = useState(false);
    const [color, setColor] = useState('white');

    const handleDelete = () => {
        deleteSubOrder(editedSubOrder.id);
        setColor('rgba(255, 182, 193, 0.50)');
    };

    return (
        <Box sx={{ padding: '5px', backgroundColor: color, boxShadow: '0px 1px 3px #999' }}>
            <Box>
                {getTitle(language, FIELDS.ORDERS.RECIPE_TITLE)}
                {existSubOrder?.recipeName}
            </Box>
            {existSubOrder && existSubOrder?.fillingId !== 0 && (
                <Box>
                    {getTitle(language, FIELDS.ORDERS.FILLING_TITLE)}
                    {existSubOrder?.fillingName}
                </Box>
            )}
            <Box>
                {getTitle(language, FIELDS.ORDERS.DESCRIPTION_ORDERS)}
                {existSubOrder?.description}
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                {existSubOrder?.price}zł
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.ORDER_COST)}
                {existSubOrder?.productCost}zł
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}:&nbsp;
                {existSubOrder?.count}
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.RECIPE_SIZE_KG)}:&nbsp;{existSubOrder?.recipeCounter}
            </Box>

            <Button variant="outlined" onClick={() => setEditMode(!editMode)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                {getTitle(language, !editMode ? FIELDS.ORDERS.EDIT : FIELDS.ORDERS.CLOSE)}
            </Button>

            {editMode && recipes && (
                <EditSubOrder key={editedSubOrder.id} subOrder={editedSubOrder} recipes={recipes} deleteSubOrder={handleDelete} saveSubOrderChanges={saveSubOrderChanges} />
            )}
        </Box>
    );
};

export default EditSubOrderHOC;
