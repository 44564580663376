interface Details {
    ru: string;
    en: string;
}

const vocabulary: Record<string, Details> = {
    // common - start
    //#region
    cancel: {
        ru: 'Отмена',
        en: 'Cancel',
    },
    confirm: {
        ru: 'Подтвердить',
        en: 'Confirm',
    },
    create: {
        ru: 'Создать',
        en: 'Create',
    },
    yes: {
        ru: 'Да',
        en: 'Yes',
    },
    no: {
        ru: 'Нет',
        en: 'No',
    },
    social_media_profile: {
        ru: 'Профиль социальной сети клиента',
        en: 'Social Media profile',
    },
    at: {
        ru: 'на',
        en: 'at',
    },
    already_exists: {
        ru: 'уже существует',
        en: 'already exists',
    },
    save: {
        ru: 'Сохранить',
        en: 'Save',
    },
    delete: {
        ru: 'Удалить',
        en: 'Delete',
    },
    search: {
        ru: 'Поиск',
        en: 'Search',
    },
    // common - end
    //#endregion

    // items - start
    //#region
    createItem: {
        ru: 'Создать ингридиент',
        en: 'Create Item',
    },
    item_name: {
        ru: 'Название',
        en: 'Item Name',
    },
    weight: {
        ru: 'Вес',
        en: 'Weight',
    },
    price: {
        ru: 'Цена',
        en: 'Price',
    },
    recipeQty: {
        ru: 'Кол-во в рецептах',
        en: 'Qty in recipes',
    },
    pcs: {
        ru: 'шт.',
        en: 'pcs',
    },
    pcs_Title: {
        ru: 'Kол-во',
        en: 'Pcs',
    },
    count: {
        ru: 'Количество',
        en: 'Count',
    },
    categories: {
        ru: 'Категории',
        en: 'Categories',
    },
    create_new_category: {
        ru: 'Создать новую категорию',
        en: 'Create New Category',
    },
    create_new_item: {
        ru: 'Создать новый ингридиент',
        en: 'Create New Item',
    },
    create_new_item_dialog_cont: {
        ru: 'Создайте новый ингридиент и выберите единицы его измерения',
        en: 'Create New Item',
    },
    create_category: {
        ru: 'Создать категорию',
        en: 'Create Category',
    },
    category_name: {
        ru: 'Название категории',
        en: 'Category Name',
    },
    unit_of_weight: {
        ru: 'Единица измерения веса',
        en: 'Unit of Weight',
    },
    modified_item: {
        ru: 'Ингридиент: ',
        en: 'Item: ',
    },
    rename_item: {
        ru: 'Переименовать ингридиент',
        en: 'Rename Item',
    },
    rename_item_dialog_cont: {
        ru: 'Новое название ингридиента',
        en: 'New item Name',
    },
    modify_categories: {
        ru: 'Модификация категорий',
        en: 'Modify Categories',
    },
    add_or_delete_categories: {
        ru: 'Добавить или удалить категории.',
        en: 'Add or Delete Categories.',
    },
    add_stock: {
        ru: 'Добавить запас',
        en: 'Add Stock',
    },
    weight_of_piece: {
        ru: 'Вес одной единицы',
        en: 'Weight of Piece',
    },
    delete_item: {
        ru: 'Удалить товар',
        en: 'Delete Item',
    },
    delete_item_info_start: {
        ru: 'Для удаления товара напишите:',
        en: 'For Deleting Item Write:',
    },
    delete_item_info_start_mobile: {
        ru: 'Для удаления напишите:',
        en: 'For Deleting Item Write:',
    },
    numSelected: {
        ru: 'выбрано',
        en: 'selected',
    },
    back_to_Items: {
        ru: 'Назад к ингредиентам',
        en: 'Back to Items',
    },
    // items - end
    //#endregion

    // login - start
    //#region
    loginTitle: {
        ru: 'Авторизация',
        en: 'Login',
    },
    loginBtn: {
        ru: 'Войти',
        en: 'Sign In',
    },
    emailHolder: {
        ru: 'электронная почта',
        en: 'email',
    },
    pswHolder: {
        ru: 'пароль',
        en: 'password',
    },
    loginError: {
        ru: 'Неверный логин или пароль.',
        en: 'Incorrect Login or Password.',
    },
    serverError: {
        ru: 'Что-то пошло не так.',
        en: 'Something went wrong.',
    },
    // login - end
    //#endregion

    // menu - start
    //#region
    profile: {
        ru: 'Профиль',
        en: 'Profile',
    },
    orders: {
        ru: 'Заказы',
        en: 'Orders',
    },
    delivery: {
        ru: 'Доставка',
        en: 'Delivery',
    },
    stock: {
        ru: 'Склад',
        en: 'Stock',
    },
    finance: {
        ru: 'Финансы',
        en: 'Finance',
    },
    settings: {
        ru: 'Настройки',
        en: 'Settings',
    },
    logout: {
        ru: 'Выйти',
        en: 'Logout',
    },
    // menu - end
    //#endregion

    // recipes - start
    //#region
    back_to_recipes: {
        ru: 'Назад к рецептам',
        en: 'Back to Recipes',
    },
    create_recipe: {
        ru: 'Создать рецепт',
        en: 'Create Recipe',
    },
    back_to_fillings: {
        ru: 'Назад к Начинкам',
        en: 'Back to Fillings',
    },
    create_filling: {
        ru: 'Создать Начинку',
        en: 'Create Filling',
    },
    create_non_linear_recipe: {
        ru: 'Создать индивидуальный рецепт',
        en: 'Create Custom Recipe',
    },
    description: {
        ru: 'Описание',
        en: 'Description',
    },
    recipe_cost: {
        ru: 'Стоимость рецепта: ',
        en: 'Recipe Cost: ',
    },
    recipe_name: {
        ru: 'Название рецепта',
        en: 'Recipe Name',
    },
    save_recipe: {
        ru: 'Сохранить рецепт',
        en: 'Save Recipe',
    },
    save_recipe_short: {
        ru: 'Сохранить',
        en: 'Save',
    },
    add_part: {
        ru: 'Добавить часть',
        en: 'Add Part',
    },
    add_part_default: {
        ru: 'Добавить часть к пропорции «1.00»',
        en: 'Add Part to Proportion «1.00»',
    },
    add_proportion: {
        ru: 'Добавить пропорцию',
        en: 'Add Proportion',
    },
    add_proportion_short: {
        ru: '+ пропорцию',
        en: 'Add Proportion',
    },
    default_proportion_1: {
        ru: 'Это стандартный рецепт на одну пропорцию, единицу измерения, часть либо штуку.',
        en: 'This is a standard recipe for one proportion, unit of measurement, part or piece.',
    },
    default_proportion_2: {
        ru: 'Для добавления других пропорций перейдите в редактирование рецепта после сохранения текущего.',
        en: 'To add other proportions, go to edit recipe after saving the current one.',
    },
    delete_part: {
        ru: 'Удалить часть',
        en: 'Delete Part',
    },
    part_name: {
        ru: 'Название части',
        en: 'Part Name',
    },
    additional_info: {
        ru: 'Доп. информация',
        en: 'Additional Info',
    },
    add_additional_info: {
        ru: 'Добавить доп. информацию',
        en: 'Add Additional Info',
    },
    add_new_item: {
        ru: 'Добавить ингридиент',
        en: 'Add Item',
    },
    item_name_recipes: {
        ru: 'Наименование ингредиента',
        en: 'Item Name',
    },
    count_recipes: {
        ru: 'Количество',
        en: 'Count',
    },
    delete_item_part: {
        ru: 'Удалить',
        en: 'Delete Item',
    },
    restore_item_part: {
        ru: 'Вернуть',
        en: 'Restore Item',
    },
    add_item_part: {
        ru: 'Добавить',
        en: 'Add Item',
    },
    add_item_part_short: {
        ru: 'Добавить ингридиент',
        en: 'Add Item',
    },
    service_size: {
        ru: 'Пропорция',
        en: 'Service size',
    },
    service_price: {
        ru: 'Цена',
        en: 'Price',
    },
    edit_recipe: {
        ru: 'Редактировать',
        en: 'Edit Recipe',
    },
    cancel_editing: {
        ru: 'Отменить',
        en: 'Cancel',
    },
    part: {
        ru: 'Часть',
        en: 'Part',
    },
    recipe: {
        ru: 'Рецепт',
        en: 'Recipe',
    },
    restore_i: {
        ru: 'Вернуть',
        en: 'Restore',
    },
    delete_i: {
        ru: 'Удалить',
        en: 'Delete',
    },
    linear_recipe: {
        ru: 'Линейный рецепт',
        en: 'Linear Recipe',
    },
    nonlinear_recipe: {
        ru: 'Нелинейный рецепт',
        en: 'Nonlinear Recipe',
    },
    delete_title: {
        ru: 'Вы уверены, что хотите удалить',
        en: 'Are you sure you want to delete',
    },
    confirm_deletion: {
        ru: 'Подтвердить удаление',
        en: 'Confirm Deletion',
    },
    used_in_recipes: {
        ru: 'Используется в рецептах',
        en: 'Used in recipes',
    },
    used_in_fillings: {
        ru: 'Используется в начинках',
        en: 'Used in fillings',
    },
    filling: {
        ru: 'Начинка',
        en: 'Filling',
    },
    edit_filling: {
        ru: 'Редактировать',
        en: 'Edit Filling',
    },
    add_relative_recipe: {
        ru: 'Добавить взаимосвязанный рецепт',
        en: 'Add a related recipe',
    },
    relative_recipes: {
        ru: 'Используется в рецептах',
        en: 'Using in recipes',
    },
    save_filling: {
        ru: 'Сохранить начинку',
        en: 'Save Filling',
    },
    filling_name: {
        ru: 'Название начинки',
        en: 'Filling Name',
    },
    // recipes - end
    //#endregion

    // orders - start
    //#region
    create_order: {
        ru: 'Создать заказ',
        en: 'Create Order',
    },
    delete_order: {
        ru: 'Удалить заказ',
        en: 'Delete Order',
    },
    delete_order_message: {
        ru: 'Хотите удалить заказ?',
        en: 'Do you want to delete the order?',
    },
    receipt_issued: {
        ru: 'Чек выдан?',
        en: 'Receipt issued?',
    },
    back_to_order_list: {
        ru: 'Вернуться к списку заказов',
        en: 'Back to Order List',
    },
    order_number: {
        ru: 'Номер: ',
        en: 'Order Number: ',
    },
    price_orders: {
        ru: 'Цена: ',
        en: 'Price: ',
    },
    description_orders: {
        ru: 'Описание: ',
        en: 'Description: ',
    },
    prepayment: {
        ru: 'Аванс: ',
        en: 'Prepayment: ',
    },
    waiting: {
        ru: 'Ожидается',
        en: 'Waiting',
    },
    received: {
        ru: 'Получено',
        en: 'Received',
    },
    order_source: {
        ru: 'Источник заказа: ',
        en: 'Order Source: ',
    },
    customer: {
        ru: 'Клиент: ',
        en: 'Customer: ',
    },
    customer_info: {
        ru: 'Информация о клиенте',
        en: 'Customer Info',
    },
    more_info: {
        ru: 'Доп. информация',
        en: 'More Info',
    },
    pick_up_date: {
        ru: 'Дата: ',
        en: 'Pickup Date: ',
    },
    pick_up_time: {
        ru: 'Время: ',
        en: 'Time: ',
    },
    rest_for_payment: {
        ru: 'Остаток к оплате: ',
        en: 'Balance to be paid: ',
    },
    close: {
        ru: 'Закрыть',
        en: 'Close',
    },
    select: {
        ru: 'Выбрать',
        en: 'Select',
    },
    edit: {
        ru: 'Редактировать',
        en: 'Edit',
    },
    order_done: {
        ru: 'Заказ выполнен',
        en: 'Order Done',
    },
    picked_up: {
        ru: 'Закрыть заказ',
        en: 'Picked Up',
    },
    order_description: {
        ru: 'Описание заказа',
        en: 'Order Description',
    },
    search_customer: {
        ru: 'Поиск клиента',
        en: 'Search Customer',
    },
    customers: {
        ru: 'Клиенты',
        en: 'Customers',
    },
    foundCustomers: {
        ru: 'Найденные клиенты',
        en: 'Found Customers',
    },
    create_customer: {
        ru: 'Создать клиента',
        en: 'Create Customer',
    },
    selected_customer: {
        ru: 'Клиент: ',
        en: 'Selected Customer: ',
    },
    no_customer: {
        ru: 'Нет клиента',
        en: 'No Customer',
    },
    customer_not_found: {
        ru: 'Клиент не найден',
        en: 'Customer Not Found',
    },
    add_suborder: {
        ru: 'Добавить подзаказ',
        en: 'Add Suborder',
    },
    delete_suborder: {
        ru: 'Удалить подзаказ',
        en: 'Delete Suborder',
    },
    sub_orders: {
        ru: 'Подзаказы: ',
        en: 'SubOrders: ',
    },
    edit_sub_orders: {
        ru: 'Редактировать подзаказы',
        en: 'Edit SubOrders',
    },
    total_order_price: {
        ru: 'Общая цена заказа: ',
        en: 'Total Order Price: ',
    },
    total_order_cost: {
        ru: 'Общая себестоимость заказа: ',
        en: 'Total Order Cost: ',
    },
    total_order_profit: {
        ru: 'Общая прибыль от заказа: ',
        en: 'Total Order Profit: ',
    },
    order_price: {
        ru: 'Цена: ',
        en: 'Price: ',
    },
    order_cost: {
        ru: 'Себестоимость: ',
        en: 'Cost: ',
    },
    order_profit: {
        ru: 'Прибыль: ',
        en: 'Profit: ',
    },
    save_order: {
        ru: 'Сохранить заказ',
        en: 'Save Order',
    },
    select_recipe: {
        ru: 'Выбрать рецепт',
        en: 'Select Recipe',
    },
    recipe_size_kg: {
        ru: 'Размер рецепта, кг',
        en: 'Recipe Size, kg',
    },
    recipe_proportion: {
        ru: 'Пропорция рецепта',
        en: 'Recipe Proportion',
    },
    count_orders: {
        ru: 'Количество',
        en: 'Count',
    },
    back_to_orders: {
        ru: 'Вернуться к заказам',
        en: 'Back to Orders',
    },
    prepayment_balance: {
        ru: 'Баланс предоплаты',
        en: 'Prepayment Balance',
    },
    prepayment_at: {
        ru: 'Дата предоплаты',
        en: 'Prepayment Date',
    },
    change_pickup_date: {
        ru: 'Изменить дату отбора',
        en: 'Change Pick-up Date',
    },
    change_prepayment: {
        ru: 'Изменить предоплату',
        en: 'Change Prepayment',
    },
    recipe_title: {
        ru: 'Название рецепта: ',
        en: 'Recipe Title: ',
    },
    filling_title: {
        ru: 'Название начинки: ',
        en: 'Filling Title: ',
    },
    full_name: {
        ru: 'Полное имя: ',
        en: 'Full Name: ',
    },
    email: {
        ru: 'Электронная почта: ',
        en: 'Email: ',
    },
    phone_number: {
        ru: 'Номер телефона: ',
        en: 'Phone Number: ',
    },
    instagram: {
        ru: 'Инстаграм: ',
        en: 'Instagram: ',
    },
    facebook: {
        ru: 'Фейсбук: ',
        en: 'Facebook: ',
    },
    required_ingredients: {
        ru: 'Необходимые ингредиенты',
        en: 'Required Ingredients',
    },
    select_dates_for_the_search: {
        ru: 'Выберите даты для поиска',
        en: 'Select Dates for the Search',
    },
    select_date_range_for_the_search: {
        ru: 'Выберите период для поиска',
        en: 'Select Date Range for the Search',
    },
    open_calendar: {
        ru: 'Календарь',
        en: 'Open Calendar',
    },
    closed_orders: {
        ru: 'Закрытые заказы',
        en: 'Closed Orders',
    },
    range_search: {
        ru: 'Диапазон дат',
        en: 'Date Range',
    },
    multiple_dates_search: {
        ru: 'Несколько дат',
        en: 'Multiple Dates',
    },
    search_options: {
        ru: 'Настройки поиска',
        en: 'Search Options',
    },
    an_order_for_the_customer: {
        ru: 'Заказ для клиента',
        en: 'An Order for the Customer',
    },
    show_order: {
        ru: 'Показать заказ',
        en: 'Show Order',
    },
    sunday: {
        ru: 'Воскресенье',
        en: 'Sunday',
    },
    monday: {
        ru: 'Понедельник',
        en: 'Monday',
    },
    tuesday: {
        ru: 'Вторник',
        en: 'Tuesday',
    },
    wednesday: {
        ru: 'Среда',
        en: 'Wednesday',
    },
    thursday: {
        ru: 'Четверг',
        en: 'Thursday',
    },
    friday: {
        ru: 'Пятница',
        en: 'Friday',
    },
    saturday: {
        ru: 'Суббота',
        en: 'Saturday',
    },
    searchedOrder: {
        ru: 'Найти дублирующий заказ',
        en: 'Find Duplicate Order',
    },
    no_records: {
        ru: 'Записей не найдено',
        en: 'No Records Found',
    },
    upload_target_images: {
        ru: 'Добавьте картинки заказа',
        en: 'Upload order images',
    },
    // orders - end
    //#endregion

    // customers - start
    //#region
    customer_name: {
        ru: 'Имя',
        en: 'Name',
    },
    customer_qty: {
        ru: 'Кол-во заказов',
        en: 'Orders Qty',
    },
    back_to_customers: {
        ru: 'Назад к клиентам',
        en: 'Back to Customers',
    },
    firstName: {
        ru: 'Имя',
        en: 'First Name',
    },
    lastName: {
        ru: 'Фамилия',
        en: 'Last Name',
    },
    phoneNumber: {
        ru: 'Номер телефона',
        en: 'Phone Number',
    },
    // customers - end
    //#endregion

    // navbar - start
    //#region
    finances: {
        ru: 'Финансы',
        en: 'Finances',
    },
    recipes: {
        ru: 'Рецепты',
        en: 'Recipes',
    },
    recipes_fillings: {
        ru: 'Начинки',
        en: 'Fillings',
    },
    recipes_decorations: {
        ru: 'Декор',
        en: 'Decorations',
    },
    items: {
        ru: 'Ингридиенты',
        en: 'Items',
    },
    customers_navbar: {
        ru: 'Клиенты',
        en: 'Customers',
    },
    orders_navbar: {
        ru: 'Заказы',
        en: 'Orders',
    },
    home: {
        ru: 'Главная',
        en: 'Home',
    },
    screenResolution: {
        ru: 'Не поддерживается на вашем устройстве, используйте Full HD разрешение экрана (1920x1080).',
        en: "Doesn't support on your device, please use Full HD screen resolution (1920x1080).",
    },
    // navbar - end
    //#endregion

    // chaiPage - start
    //#region
    chaiPage: {
        ru: 'Может к чаю нужен ТОРТ?',
        en: 'Maybe you need a CAKE for tea?',
    },
    // chaiPage - end
    //#endregion

    // homePage - start
    //#region
    income: {
        ru: 'Доход',
        en: 'Income',
    },
    outcome: {
        ru: 'Расход',
        en: 'Outcome',
    },
    delta: {
        ru: 'Дельта',
        en: 'Delta',
    },
    weekStats: {
        ru: 'Недельная стата',
        en: 'Week Stats',
    },
    monthStats: {
        ru: 'Месячная стата',
        en: 'Week Stats',
    },
    prevMonth: {
        ru: 'Пред. месяц',
        en: 'Prev Month',
    },
    prevWeeks: {
        ru: 'Пред. неделя',
        en: 'Prev Weeks',
    },
    nextMonth: {
        ru: 'След. месяц',
        en: 'Next Month',
    },
    nextWeeks: {
        ru: 'След. неделя',
        en: 'Next Weeks',
    },
    home_page_info_one: {
        ru: 'На этой странице представлены данные о ближайших заказах и недостающих ингредиентах для выполнения этих заказов.',
        en: 'This page provides information about upcoming orders and missing ingredients to fill those orders.',
    },
    home_page_info_two: {
        ru: 'Если вы видите этот заголовок - это значит на ближайшее время заказывает нет.',
        en: 'If you see this title, it means there are no orders for the near future.',
    },
    // homePage - end
    //#endregion
};

const getTitle = (lang: string, field: keyof typeof vocabulary): string => {
    const selectedField = vocabulary[field];
    if (selectedField) {
        return selectedField[lang as keyof Details];
    }
    return '';
};

export default getTitle;
