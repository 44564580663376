import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FIELDS } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getRecipeSize } from '../../../common/helpers';

interface Props {
    subOrder: ISubOrder;
    recipes: IRecipe[];
    deleteSubOrder: () => void;
    saveSubOrderChanges: (subOrder: ISubOrder) => void;
}

const EditSubOrder = ({ subOrder, recipes, deleteSubOrder, saveSubOrderChanges }: Props) => {
    const { language } = useLanguage();
    const [editedSubOrder, setEditedSubOrder] = useState<ISubOrder>({ ...subOrder });
    const [newPrice, setNewPrice] = useState<number>(editedSubOrder.price);
    const [orderCount, setOrderCount] = useState<number>(editedSubOrder.count);
    const [isValueSelected, setIsValueSelected] = useState(editedSubOrder.recipeName && editedSubOrder.recipeName !== '');
    const [fillingName, setFillingName] = useState(editedSubOrder.fillingName);
    const [selectedRecipe, setSelectedRecipe] = useState<IRecipe>();
    const [recipeSize, setRecipeSize] = useState<string[]>([]);
    const [recipeCost, setRecipeCost] = useState(editedSubOrder.productCost);
    const [newProfit, setNewProfit] = useState<number>(recipeCost - newPrice);
    const [initRender, setInitRender] = useState(true);

    const size =
        editedSubOrder.recipeCounter.toString().length === 1 && editedSubOrder.recipeCounter !== 0
            ? `${editedSubOrder.recipeCounter}.00`
            : editedSubOrder.recipeCounter.toString().length === 3
            ? `${editedSubOrder.recipeCounter}0`
            : editedSubOrder.recipeCounter === 0
            ? ''
            : editedSubOrder.recipeCounter.toString() || '1.00';
    const inputCountRef = useRef<HTMLInputElement>(null);
    const inputPriceRef = useRef<HTMLInputElement>(null);
    const fillingError = subOrder.fillingId === 0;

    let recipesNameList: string[] = [];
    recipes?.map((r) => recipesNameList.push(r.recipeName || ''));

    const handleRecipeName = (e: any, name: string | null) => {
        setIsValueSelected(!!name);
        if (name) {
            recipes?.forEach((r) => {
                const recipe = r.recipeName === name;

                if (recipe) {
                    setEditedSubOrder((prevState) => {
                        return {
                            ...prevState,
                            recipeName: name,
                            recipeId: r.id,
                        };
                    });

                    setSelectedRecipe(r);
                    if (r.isLinear) {
                        setRecipeSize(getRecipeSize(r));
                    } else {
                        const sizes = r.proportionInfos.map((pi) => pi.proportion);
                        setRecipeSize(sizes);
                    }

                    return;
                }
            });
        }
    };

    const handleFilling = (e: any, name: any) => {
        setFillingName(name.props.value);
        const fillingId = selectedRecipe?.fillings && selectedRecipe?.fillings.length > 0 ? selectedRecipe.fillings.find((el) => el.fillingName === name.props.value)?.id || 0 : 0;
        setEditedSubOrder((prev) => {
            return {
                ...prev,
                fillingId: fillingId,
            };
        });
    };

    const handleRecipeCounter = (e: any, counter: any) => {
        setEditedSubOrder((prev) => {
            return {
                ...prev,
                recipeCounter: Number(counter.props.value),
            };
        });
    };

    const handlePrice = (e: any) => {
        setNewPrice(Number(e.target.value));
        setEditedSubOrder((prev) => {
            return {
                ...prev,
                price: Number(e.target.value),
            };
        });
    };

    const handleOrderCount = (e: any) => {
        setOrderCount(Number(e.target.value));
        setEditedSubOrder((prev) => {
            return {
                ...prev,
                count: Number(e.target.value),
            };
        });
    };

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const autoCompleteLabelStyle = { '& .MuiInputLabel-root': { pointerEvents: 'none' } };
    const { simpleBtnStyle } = getCommonStyles();

    const getRecipeSizes = () => {
        recipes?.forEach((r) => {
            const recipe = r.recipeName === editedSubOrder.recipeName;
            if (recipe) {
                setSelectedRecipe(r);
                if (r.isLinear) {
                    setRecipeSize(getRecipeSize(r));
                } else {
                    const sizes = r.proportionInfos.map((pi) => pi.proportion);
                    setRecipeSize(sizes);
                }

                return;
            }
        });
    };

    const calculateProductCost = () => {
        const hasFillings = selectedRecipe?.fillings && selectedRecipe?.fillings.length > 0;
        const fillingId = hasFillings && editedSubOrder.fillingId !== 0 ? selectedRecipe.fillings.find((el) => el.id === editedSubOrder.fillingId)?.id || 0 : 0;
        const recipeCost = selectedRecipe?.proportionInfos && selectedRecipe?.proportionInfos.length > 0 ? Number(selectedRecipe?.proportionInfos[0].cost) : 0;
        const fillingCost = hasFillings ? Number(selectedRecipe?.fillings.find((el) => el.id === fillingId)?.proportionInfos[0].cost || 0) : 0;
        const tempCost = (fillingCost + recipeCost) * editedSubOrder.count * editedSubOrder.recipeCounter;

        setEditedSubOrder((prev) => {
            return {
                ...prev,
                productCost: tempCost,
            };
        });
        setRecipeCost(tempCost);
        setNewProfit(editedSubOrder.price - tempCost);
    };

    useEffect(() => {
        const r = recipes?.find((el) => el.id === subOrder.recipeId);
        getRecipeSizes();

        if (r) {
            setSelectedRecipe(r);
        }
        setInitRender(false);
    }, []);

    useEffect(() => {
        if (!initRender && editedSubOrder.fillingId !== 0 && (!selectedRecipe?.fillings || selectedRecipe.fillings.length == 0)) {
            setFillingName('');
            setEditedSubOrder((prev) => {
                return {
                    ...prev,
                    fillingId: 0,
                    fillingName: '',
                };
            });
        }

        saveSubOrderChanges(editedSubOrder);
    }, [editedSubOrder]);

    useEffect(() => {
        calculateProductCost();
    }, [recipeCost, newPrice, orderCount, size, selectedRecipe, fillingName]);

    return (
        <Box style={{ padding: 10 }}>
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 390 }}>
                <Autocomplete
                    disablePortal
                    id="recipes"
                    placeholder={getTitle(language, FIELDS.ORDERS.SELECT_RECIPE)}
                    options={recipesNameList}
                    value={editedSubOrder.recipeName}
                    renderInput={(params) => <TextField {...params} required label={getTitle(language, FIELDS.ORDERS.SELECT_RECIPE)} />}
                    onChange={handleRecipeName}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderWidth: '2px',
                                borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                            },
                            '&:hover fieldset': {
                                borderWidth: '2px',
                                borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                            },
                            '&.Mui-focused fieldset': {
                                borderWidth: '2px',
                                borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                            },
                        },
                    }}
                />
                <FormControl
                    variant="outlined"
                    size="medium"
                    required
                    error={size === ''}
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                            borderColor: size === '0.00' || !size ? 'red' : 'green',
                        },
                    }}
                >
                    <InputLabel id="size_label">{getTitle(language, FIELDS.RECIPES.SERVICE_SIZE)}</InputLabel>
                    <Select
                        labelId="size_label"
                        id="recipe_size"
                        value={size}
                        onChange={handleRecipeCounter}
                        label={getTitle(language, FIELDS.RECIPES.SERVICE_SIZE)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&:hover fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                            },
                        }}
                    >
                        {recipeSize?.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedRecipe?.fillings && selectedRecipe.fillings.length > 0 && (
                    <FormControl
                        variant="outlined"
                        size="medium"
                        required
                        error={fillingError}
                        sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '2px',
                                borderColor: fillingError ? 'red' : 'green',
                            },
                        }}
                    >
                        <InputLabel id="filling_label">{getTitle(language, FIELDS.RECIPES.FILLING)}</InputLabel>
                        <Select
                            labelId="filling_label"
                            id="filling_select"
                            value={fillingName}
                            onChange={handleFilling}
                            label={getTitle(language, FIELDS.RECIPES.FILLING)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderWidth: '2px',
                                    },
                                    '&:hover fieldset': {
                                        borderWidth: '2px',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderWidth: '2px',
                                    },
                                },
                            }}
                        >
                            {selectedRecipe.fillings.map((option) => (
                                <MenuItem key={option.fillingName} value={option.fillingName}>
                                    {option.fillingName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <TextField
                    inputRef={inputCountRef}
                    required
                    id={'count'}
                    label={getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}
                    placeholder={getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}
                    value={orderCount}
                    focused
                    type="number"
                    onChange={handleOrderCount}
                    onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                    style={{ marginBottom: 10 }}
                    color={orderCount === 0 ? 'error' : 'success'}
                />
                <TextField
                    inputRef={inputPriceRef}
                    required
                    id={'price'}
                    label={getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                    placeholder={getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                    value={newPrice}
                    focused
                    type="number"
                    onChange={handlePrice}
                    onFocus={() => inputPriceRef.current && inputPriceRef.current.select()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                    }}
                    style={{ marginBottom: 10 }}
                    color={newPrice === 0 ? 'error' : 'success'}
                />
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_COST)} {recipeCost} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_PROFIT)} {newProfit} zł
                </Box>
                <Button variant="outlined" onClick={deleteSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, FIELDS.COMMON.DELETE)}
                </Button>
            </Stack>
        </Box>
    );
};

export default EditSubOrder;
