import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IRecipePartItem } from '../../../models/Recipe/IRecipePartItem';
import { getStyles } from '../recipeCommon';

interface RecipePartItemProps {
    recipePart: IRecipePartItem;
    size: number;
}

const FillingPartItem = ({ recipePart, size }: RecipePartItemProps) => {
    const { recipePartItemStyle } = getStyles();
    const [data, setData] = useState<any>([{}]);
    const recipePartItemData = (
        <>
            {Math.round(recipePart.proportions[0].quantity * size)}
            &nbsp;
            {recipePart.unitOfWeight}
        </>
    );

    useEffect(() => {
        const filteredData = recipePart.proportions.filter((el: any) => el.proportion === size);
        setData(filteredData);
    }, []);

    return (
        <Box sx={recipePartItemStyle}>
            <Box component={'span'} sx={{ pr: '10px' }}>
                {recipePart.itemName}
            </Box>
            {recipePartItemData}
        </Box>
    );
};

export default FillingPartItem;
