import { ENDPOINTS } from '../common/endpoints';
import { IFillingPagination } from '../models/IPagination';
import { IFilling } from '../models/Recipe/Filling/IFilling';
import { IFillingLight } from '../models/Recipe/Filling/IFillingsLight';
import { IRecipe } from '../models/Recipe/IRecipe';
import { baseAPI } from './BaseAPI';

// TODO: move types to modals folder
type SearchPropsType = {
    page: number;
    limit: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCost: boolean;
};

type SearchResponseType = {
    data: IRecipe[];
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
};

export const fillingAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        // getAllFillings: build.query<IFilling[], void>({
        //     query: () => ({
        //         url: ENDPOINTS.FILLINGS.GET_ALL_FILLINGS,
        //         method: 'GET',
        //     }),

        //     providesTags: (result) => ['Fillings'],
        // }),

        getFillings: build.query<IFillingPagination, SearchPropsType>({
            query: ({ page, limit, searchPhrase, categories, descending, orderByName, orderByCost }: SearchPropsType) => ({
                url: `${ENDPOINTS.FILLINGS.GET_FILLINGS}?page=${page}&limit=${limit}&searchPhrase=${searchPhrase}&categories=${categories}&descending=${descending}&orderByName=${orderByName}&orderByCost=${orderByCost}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Fillings'],
        }),

        saveFilling: build.mutation<IFilling, IFilling>({
            query: (newRecipe: any) => ({
                url: ENDPOINTS.FILLINGS.SAVE_FILLINGS,
                method: 'POST',
                body: newRecipe,
            }),

            invalidatesTags: ['Fillings', 'Filling', 'Recipes'],
        }),

        getFilling: build.query<IFilling, string | number>({
            query: (id: string | number) => ({
                url: `${ENDPOINTS.FILLINGS.GET_FILLING}/${id}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Filling'],
        }),

        deleteFillings: build.mutation<void, number[]>({
            query: (deletedFillings: number[]) => ({
                url: ENDPOINTS.FILLINGS.DELETE_FILLINGS,
                method: 'DELETE',
                body: deletedFillings,
            }),

            invalidatesTags: (result) => ['Fillings', 'Recipes'],
        }),

        getAllFillingsLight: build.query<IFillingLight[], void>({
            query: () => ({
                url: ENDPOINTS.FILLINGS.GET_ALL_FILLINGS_LIGHT,
                method: 'GET',
            }),

            providesTags: (result) => ['RecipesLight'],
        }),
    }),
});
