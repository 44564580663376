import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { DTO_STATE, FIELDS, IS_DELETED, ORDER_STAGES } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { compareObjects, getDateTime, hasStateNewOrDeleted } from '../../../common/helpers';
import { ICustomer } from '../../../models/ICustomer';
import { IOrder } from '../../../models/Order/IOrder';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { orderAPI } from '../../../services/OrderServices';
import { recipeAPI } from '../../../services/RecipeServices';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import EditSubOrderHOC from './EditSubOrderHOC';
import { OrderValidator } from '../../../validators/OrderValidator';

interface EditOrderProps {
    order: IOrder;
    handleEditMode: () => void;
}

const EditOrder = ({ order, handleEditMode }: EditOrderProps) => {
    const { language } = useLanguage();
    const { data: recipes, error: getRecipesError, isLoading: getRecipesIsLoading, isSuccess: getRecipesIsSuccess } = recipeAPI.useGetRecipesForOrderQuery();
    const [saveOrder, { error: saveOrderError, isSuccess: saveOrderIsSuccess }] = orderAPI.useSaveOrderMutation();
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();
    const [editedOrder, setEditedOrder] = useState<IOrder>({ ...order });
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const customerData = order.customer!.instagram || order.customer!.faceBook || order.customer!.name;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newPrice, setNewPrice] = useState<number>(order.price);
    const [prepaymentDate, setPrepaymentDate] = useState<string>();
    const [prepaymentBalance, setPrepaymentBalance] = useState<number>(0);
    const inputBalanceRef = useRef<HTMLInputElement>(null);
    const validator = useMemo(() => OrderValidator.getInstance(editedOrder, recipes || []), [editedOrder, recipes]);
    const forPayment = order.price - order.prepayment!.prepayment - prepaymentBalance;
    const handelDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    const datePickUp = getDateTime(order.pickUpAt);
    const [changePickUpDate, setChangePickUpDate] = useState<boolean>(false);
    const [changePrepayment, setChangePrepayment] = useState<boolean>(false);

    const [newPickUpDate, setNewPickUpDate] = useState<string>('');

    const handlePrepaymentBalance = (e: any) => {
        const prepayment = Number(e.target.value);
        setPrepaymentBalance(prepayment);
        const prepaymentState =
            prepayment === order.prepayment?.prepayment && (prepaymentDate || order.prepayment.prepaymentAt) === order.prepayment.prepaymentAt
                ? DTO_STATE.DTO_UNCHANGED
                : DTO_STATE.DTO_NEW;

        setEditedOrder((prev) => {
            return {
                ...prev,
                prepayment: {
                    ...order.prepayment!,
                    prepaymentAt: prepaymentDate || order.prepayment?.prepaymentAt,
                    prepayment: prepayment,
                    state: order.prepayment?.prepayment === prepayment ? DTO_STATE.DTO_UNCHANGED : DTO_STATE.DTO_NEW,
                    orderId: prepaymentState === DTO_STATE.DTO_NEW ? editedOrder.id : 0,
                },
            };
        });
    };
    const handlePickUpDateTime = (e: any) => {
        processDate(e);
    };
    const handlePrepaymentDate = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})');
        if (exDateTime.test(e.target.value)) {
            setPrepaymentDate(e.target.value);
            setEditedOrder((prev) => {
                return {
                    ...prev,
                    prepayment: {
                        ...order.prepayment!,
                        prepayment: prepaymentBalance,
                        prepaymentAt: e.target.value,
                        state: DTO_STATE.DTO_NEW,
                        orderId: editedOrder.id,
                    },
                };
            });
        } else {
            setPrepaymentDate('');
            setEditedOrder((prev) => {
                return {
                    ...prev,
                    prepayment: {
                        ...order.prepayment!,
                        prepayment: prepaymentBalance,
                        prepaymentAt: order.prepayment?.prepaymentAt || '',
                        state: order.prepayment?.prepayment === prepaymentBalance ? DTO_STATE.DTO_UNCHANGED : DTO_STATE.DTO_NEW,
                    },
                };
            });
        }
    };

    const processDate = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})');
        if (exDateTime.test(e.target.value)) {
            setEditedOrder((prev) => {
                return {
                    ...prev,
                    pickUpAt: e.target.value,
                };
            });
            setNewPickUpDate(e.target.value);
        }
    };

    let init: ISubOrder[] = [];
    order.subOrders?.forEach((subOrder) => {
        init.push({ ...subOrder });
    });

    const handleSaveOrder = () => {
        const finalOrder = { ...editedOrder, state: DTO_STATE.DTO_MODIFIED };
        // TODO: disable save button when click it
        saveOrder(finalOrder);
    };

    const setOrderDescription = (e: any) => {
        setEditedOrder((prev) => {
            return {
                ...prev,
                description: e.target.value,
            };
        });
    };

    const saveSubOrderChanges = (subOrder: ISubOrder) => {
        let subOrders = editedOrder.subOrders?.map((el) => {
            if (el.id === subOrder.id) {
                if (subOrder.state !== DTO_STATE.DTO_NEW) {
                    const isHasChanged = !compareObjects(
                        subOrder,
                        order.subOrders?.find((e) => e.id === subOrder.id)
                    );
                    if (isHasChanged) {
                        subOrder.state = DTO_STATE.DTO_MODIFIED;
                    } else {
                        subOrder.state = DTO_STATE.DTO_UNCHANGED;
                    }
                }
                return subOrder;
            }
            return el;
        });
        setEditedOrder((prev) => {
            return { ...prev, subOrders: [...(subOrders || [])] };
        });
    };

    const handleOrderPrice = () => {
        let price = 0;
        editedOrder.subOrders?.forEach((element) => {
            price += element.price;
        });

        setNewPrice(price);
    };
    useEffect(() => {
        const isHasChanged = editedOrder.subOrders?.length === order.subOrders?.length ? !compareObjects(editedOrder, order) : true;
        const hasNewState = hasStateNewOrDeleted(editedOrder.subOrders || []) || hasStateNewOrDeleted([editedOrder.prepayment]);
        const hasDiff = isHasChanged || hasNewState;
        const validOrder = validator.validate();

        if (!hasDiff && validOrder) {
            setIsSaveDisabled(true);
        }
        if (hasDiff && validOrder) {
            setIsSaveDisabled(false);
        }
        if (hasDiff && !validOrder) {
            setIsSaveDisabled(true);
        }
    }, [editedOrder]);

    const deleteSubOrder = (subOrderId: number | string) => {
        let subOrders: ISubOrder[] = [...(editedOrder.subOrders || [])];
        if (subOrderId.toString().includes('-')) {
            subOrders = subOrders?.filter((e) => e.id.toString() !== subOrderId.toString());
        } else {
            subOrders = subOrders.map((e) => {
                if (e.id === subOrderId) {
                    return { ...e, state: DTO_STATE.DTO_DELETED };
                }
                return e;
            });
        }

        setEditedOrder((prev) => {
            return { ...prev, subOrders: [...subOrders] };
        });
    };

    const addNewSubOrder = () => {
        setEditedOrder((prev) => {
            return {
                ...prev,
                subOrders: [
                    ...(prev.subOrders || []),
                    {
                        id: crypto.randomUUID(),
                        recipeName: '',
                        recipeId: 0,
                        description: '',
                        price: 0,
                        productCost: 0,
                        count: 1,
                        recipeCounter: 0,
                        orderId: order.id,
                        isDeleted: IS_DELETED.ACTIVE,
                        state: DTO_STATE.DTO_NEW,
                        fillingId: 0,
                    },
                ],
            };
        });
    };

    useEffect(() => {
        if (!changePickUpDate) {
            setEditedOrder((prev) => {
                return {
                    ...prev,
                    pickUpAt: order.pickUpAt,
                };
            });
            setNewPickUpDate('');
        }
    }, [changePickUpDate]);

    useEffect(() => {
        if (!changePrepayment) {
            setEditedOrder((prev) => {
                return {
                    ...prev,
                    prepayment: { ...order.prepayment! },
                };
            });
            setPrepaymentBalance(0);
            setPrepaymentDate('');
        }
    }, [changePrepayment]);

    return (
        <Box
            style={{
                margin: 10,
            }}
        >
            {saveOrderIsSuccess && <>{handleEditMode()}</>}
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 400 }}>
                <Button variant="outlined" onClick={handleEditMode} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, FIELDS.ORDERS.BACK_TO_ORDERS)}
                </Button>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_NUMBER)}
                    {order.orderNumber}
                </Box>
                <TextField
                    style={{ marginBottom: 10 }}
                    required
                    multiline
                    id={'description'}
                    label={getTitle(language, FIELDS.ORDERS.DESCRIPTION_ORDERS)}
                    placeholder={getTitle(language, FIELDS.ORDERS.DESCRIPTION_ORDERS)}
                    value={editedOrder.description}
                    onChange={setOrderDescription}
                />
                <Box>
                    {getTitle(language, FIELDS.ORDERS.TOTAL_ORDER_COST)}
                    {order.cost} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.PREPAYMENT)}
                    {order.stage === ORDER_STAGES.PREPAID
                        ? `${getTitle(language, FIELDS.ORDERS.RECEIVED)} ${order.prepayment!.prepayment} zł ${order.prepayment?.prepaymentAt}`
                        : `${getTitle(language, FIELDS.ORDERS.WAITING)}`}
                    <Box style={{ paddingTop: 10 }}>
                        <Button variant="outlined" onClick={() => setChangePrepayment(!changePrepayment)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {!changePickUpDate ? getTitle(language, FIELDS.ORDERS.CHANGE_PREPAYMENT) : FIELDS.COMMON.CANCEL}
                        </Button>
                        {changePrepayment && (
                            <Box style={{ paddingTop: 10 }}>
                                <TextField
                                    inputRef={inputBalanceRef}
                                    required
                                    id={'prepaymentBalance'}
                                    label={getTitle(language, FIELDS.ORDERS.PREPAYMENT_BALANCE)}
                                    type="number"
                                    placeholder={getTitle(language, FIELDS.ORDERS.PREPAYMENT_BALANCE)}
                                    value={prepaymentBalance}
                                    focused
                                    onChange={handlePrepaymentBalance}
                                    onFocus={() => inputBalanceRef.current && inputBalanceRef.current.select()}
                                    style={{ marginBottom: 10, paddingRight: 20 }}
                                />
                                <TextField
                                    style={{ marginBottom: 10 }}
                                    required
                                    id={'prepaymentAt'}
                                    label={getTitle(language, FIELDS.ORDERS.PREPAYMENT_AT)}
                                    type="date"
                                    placeholder={getTitle(language, FIELDS.ORDERS.PREPAYMENT_AT)}
                                    onChange={handlePrepaymentDate}
                                    focused
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                {getTitle(language, FIELDS.ORDERS.CUSTOMER)}
                {customerData}
                <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, FIELDS.ORDERS.CUSTOMER_INFO)}
                </Button>
                <Box style={{ margin: 10 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getTitle(language, FIELDS.ORDERS.SUB_ORDERS)}
                        &nbsp;
                        <Button variant="outlined" onClick={addNewSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, FIELDS.ORDERS.ADD_SUBORDER)}
                        </Button>
                    </Box>
                    {editedOrder.subOrders?.map((subOrder) => (
                        <EditSubOrderHOC
                            key={`hoc_${subOrder.id}`}
                            existSubOrder={order.subOrders?.find((el) => el.id === subOrder.id)}
                            editedSubOrder={subOrder}
                            recipes={recipes}
                            handleOrderPrice={handleOrderPrice}
                            deleteSubOrder={deleteSubOrder}
                            saveSubOrderChanges={saveSubOrderChanges}
                        />
                    ))}
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.TOTAL_ORDER_PRICE)}
                    {newPrice} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                    {datePickUp?.date} | {getTitle(language, FIELDS.ORDERS.PICK_UP_TIME)}
                    {datePickUp?.time}
                </Box>
                <Button variant="outlined" onClick={() => setChangePickUpDate(!changePickUpDate)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {!changePickUpDate ? getTitle(language, FIELDS.ORDERS.CHANGE_PICKUP_DATE) : FIELDS.COMMON.CANCEL}
                </Button>
                {changePickUpDate && (
                    <Box style={{ paddingTop: 10 }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'pickUpDate'}
                            label={getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                            type="datetime-local"
                            placeholder={getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                            onChange={handlePickUpDateTime}
                            onClick={handlePickUpDateTime}
                            color={!newPickUpDate ? 'error' : 'success'}
                            focused
                        />
                    </Box>
                )}
                <Box>
                    {getTitle(language, FIELDS.ORDERS.REST_FOR_PAYMENT)}
                    {forPayment} zł
                </Box>
                <Button variant="outlined" onClick={handleSaveOrder} disabled={isSaveDisabled} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, FIELDS.ORDERS.SAVE_ORDER)}
                </Button>
            </Stack>
            <Dialog fullWidth open={dialogOpen} onClose={handelDialogOpen}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{getTitle(language, FIELDS.ORDERS.CUSTOMER_INFO)}</Box>
                        <Box>
                            <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                {getTitle(language, FIELDS.ORDERS.CLOSE)}
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>{order.customer && <CustomerInDialog key={order.customer.id} customer={order.customer} />}</DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditOrder;

interface CustomerInDialogProps {
    customer: ICustomer;
}

const CustomerInDialog = ({ customer }: CustomerInDialogProps) => {
    const { language } = useLanguage();

    return (
        <Box style={{ backgroundColor: 'rgba(245, 245, 220, 0.7)', padding: '10px' }}>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.FULL_NAME)}
                {customer.name}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.EMAIL)}
                {customer.email}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.PHONE_NUMBER)}
                {customer.phoneNumber}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.INSTAGRAM)}
                {customer.instagram}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.FACEBOOK)}
                {customer.faceBook}
            </Box>
        </Box>
    );
};
