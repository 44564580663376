import { ENDPOINTS } from '../common/endpoints';
import { baseAPI } from './BaseAPI';

export const imageAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        saveImages: build.mutation<void, FormData>({
            query: (data: FormData) => ({
                url: ENDPOINTS.IMAGES.SAVE_IMAGES,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const fetchImage = async (fileName: string): Promise<{ imageUrl: string; error: string }> => {
    try {
        const response = await fetch(`${ENDPOINTS.BASE_API_URL}${ENDPOINTS.IMAGES.GET_IMAGE}?fileName=${fileName}`, {
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Ошибка загрузки изображения');
        }

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        return { imageUrl, error: '' };
    } catch (error) {
        return { imageUrl: '', error: (error as Error).message || 'Ошибка при получении изображения' };
    }
};
