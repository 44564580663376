import { TextField, Autocomplete, ListItem, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { IFillingLight } from '../../../models/Recipe/Filling/IFillingsLight';
import { IRecipeFilling } from '../../../models/Recipe/IRecipeFilling';
import { useLanguage } from '../../../context/LanguageContext';
import { getStyles } from '../recipeCommon';
import { getMedia } from '../../../common/mediaQuery';
import { DTO_STATE, FIELDS } from '../../../common/dictionary';
import getTitle from '../../../common/translations';

interface Props {
    fillings: IFillingLight[] | undefined;
    setFillings: (fillings: IRecipeFilling[]) => void;
    recipeId: string | number;
    recipeName: string | undefined;
    selectedFillings?: IRecipeFilling[] | undefined;
}

const RelativeFillings = ({ fillings, setFillings, recipeId, recipeName, selectedFillings }: Props) => {
    const { language } = useLanguage();
    const { autoCompleteLabelStyle } = getStyles();
    const { isTablet } = getMedia();

    const itemsObj: IRecipeFilling[] | undefined = fillings?.map((item) => {
        if (selectedFillings) {
            const index = selectedFillings?.findIndex((el) => el.recipeName === item.fillingName);
            if (index >= 0) {
                return selectedFillings[index];
            }
        }
        return { recipeName: recipeName, recipeId: recipeId, fillingId: item.id, fillingName: item.fillingName, state: DTO_STATE.DTO_NEW };
    });

    const [selectedOptions, setSelectedOptions] = useState<IRecipeFilling[]>(selectedFillings || []);

    useEffect(() => {
        setSelectedOptions(selectedFillings || []);
    }, [selectedFillings]);

    const handleItemName = (e: any, newSelected: IRecipeFilling[]) => {
        const updatedSelection = selectedOptions
            .map((option) => {
                if (!newSelected.some((newItem) => newItem.fillingId === option.fillingId)) {
                    if (option.state !== DTO_STATE.DTO_NEW) {
                        return { ...option, state: DTO_STATE.DTO_DELETED };
                    }
                    return null;
                }
                if (option.state === DTO_STATE.DTO_DELETED) {
                    return { ...option, state: DTO_STATE.DTO_UNCHANGED };
                }
                return option;
            })
            .filter((item): item is IRecipeFilling => item !== null);

        const addedNewItems = newSelected
            .filter((newItem) => !selectedOptions.some((option) => option.fillingId === newItem.fillingId))
            .map((newItem) => ({
                ...newItem,
                state: DTO_STATE.DTO_NEW,
            }));

        const finalSelection = [...updatedSelection, ...addedNewItems];

        setSelectedOptions(finalSelection);
        setFillings(finalSelection);
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ ml: '10px', pt: '10px', mr: '10px', minWidth: '235px', maxWidth: '1082px' }}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="itemName"
                    placeholder={getTitle(language, FIELDS.NAV_BAR.RECIPES_FILLINGS)}
                    options={itemsObj || []}
                    getOptionLabel={(option) => option.fillingName ?? ''}
                    value={selectedOptions.filter((option) => option.state !== DTO_STATE.DTO_DELETED)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            focused
                            placeholder={getTitle(language, FIELDS.NAV_BAR.RECIPES_FILLINGS)}
                            label={getTitle(language, FIELDS.NAV_BAR.RECIPES_FILLINGS)}
                        />
                    )}
                    onChange={(_, newValue) => handleItemName(_, newValue as IRecipeFilling[])}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.fillingName} sx={isTablet ? { fontSize: '0.8rem', minHeight: '25px !important' } : {}}>
                            {option.fillingName}
                        </ListItem>
                    )}
                    isOptionEqualToValue={(option, value) => option.fillingId === value.fillingId}
                    sx={{ ...autoCompleteLabelStyle }}
                />
            </Box>
        </Box>
    );
};

export default RelativeFillings;
