import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENDPOINTS } from '../common/endpoints';

const requestHeaders: HeadersInit = new Headers();
requestHeaders.set('Access-Control-Allow-Credentials', 'false');

export const baseAPI = createApi({
    reducerPath: 'baseAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: ENDPOINTS.BASE_API_URL,
        credentials: 'include',
        //headers: requestHeaders,
    }),
    tagTypes: ['Order', 'Item', 'Auth', 'Category', 'Customer', 'Recipe', 'Recipes', 'User', 'Fillings', 'Filling', 'RecipesLight', 'RecipesForOrder'],

    endpoints: (build) => ({}),
});
