import { useCallback, useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DTO_STATE, FIELD_NAMES, FIELDS, IS_DELETED, RECIPE_SIZE, UNIT_OF_WEIGHT } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getAllItems, getRecipesLight, saveFillingPost, saveRecipePost } from '../../dataManager';
import { getCommonStyles } from '../../../../common/commonStyles';
import { getMedia } from '../../../../common/mediaQuery';
import { IRecipeFilling } from '../../../../models/Recipe/IRecipeFilling';
import { IFilling } from '../../../../models/Recipe/Filling/IFilling';
import CreateRecipeItems from './CreateRecipeItems';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import RecipeRelatives from './RecipeRelatives';

interface CreateRecipeProps {
    setCreateFilling: (state: boolean) => void;
    setIsAllFillingsIsLoading: (arg: boolean) => void;
}

const CreateFilling = ({ setCreateFilling, setIsAllFillingsIsLoading }: CreateRecipeProps) => {
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();
    const { isMobile } = getMedia();

    const [fillingName, setFillingName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [proportions, setProportions] = useState('1.00');

    const [newFillingInitState] = useState<IFilling>({
        id: crypto.randomUUID(),
        items: [],
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
        fillingName: '',
        fillingDescription: '',
        recipes: [],
        proportionInfos: [],
    });

    const [newFilling, setNewFilling] = useState<IFilling>(newFillingInitState);
    const [newItems, setNewItems] = useState<IRecipePartItem[]>([
        {
            id: crypto.randomUUID(),
            itemId: -1,
            itemName: '',
            unitOfWeight: UNIT_OF_WEIGHT.G,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            proportions: [],
            fillingId: newFilling.id,
        },
    ]);
    newFilling.items = [...newItems];
    const addItem = () => {
        setNewItems((prevState) => {
            return [
                ...prevState,
                {
                    id: crypto.randomUUID(),
                    itemId: -1,
                    itemName: '',
                    count: 0,
                    unitOfWeight: UNIT_OF_WEIGHT.G,
                    isDeleted: IS_DELETED.ACTIVE,
                    state: DTO_STATE.DTO_NEW,
                    proportions: [],
                    fillingId: newFilling.id,
                },
            ];
        });
    };
    const [disabledSaveFillingButton, setDisabledSaveFillingButton] = useState(false);
    const [isNameAndDescriptionEmpty, setIsNameAndDescriptionEmpty] = useState(true);
    const [isPartNameEmpty, setIsPartNameEmpty] = useState(true);
    const [isNewItemNameEmpty, setIsNewItemNameEmpty] = useState(true);
    const [isNewItemCountEmpty, setIsNewItemCountEmpty] = useState(true);
    const [isButtonDisable, setIsButtonDisable] = useState(false); // todo modify isButtonDisable
    const { items, getAllItemsError, getAllItemsIsLoading, getAllItemsIsSuccess } = getAllItems();
    const { recipes, isLoadingRecipe, getRecipeError, isSuccessRecipe } = getRecipesLight();
    const { saveFilling, saveFillingError, isSaveFillingSuccess } = saveFillingPost();

    const handleRelativeRecipe = (recipes: IRecipeFilling[]) => {
        setNewFilling((prev) => {
            return {
                ...prev,
                recipes: [...recipes],
            };
        });
    };

    const handleSaveFilling = () => {
        setDisabledSaveFillingButton(true);
        saveFilling(newFilling).then((res) => {
            setIsAllFillingsIsLoading(true);
            if ('error' in res) setDisabledSaveFillingButton(false);
        });
    };

    const setNewItemValues = (fillingItem: IRecipePartItem) => {
        const itemIndex = newItems.findIndex((item) => item.id === fillingItem.id);
        newItems[itemIndex] = fillingItem;

        setNewFilling((prev: any) => {
            return {
                ...prev,
                items: [...newItems],
            };
        });
    };

    const handleDeleteItem = (itemId: number | string) => {
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems.splice(index, 1);
        setNewFilling((prev: any) => {
            return {
                ...prev,
                items: [...newItems],
            };
        });
    };

    useEffect(() => {
        if (isSaveFillingSuccess) {
            setDisabledSaveFillingButton(false);
            setCreateFilling(false);
        }
    }, [isSaveFillingSuccess]);

    // useEffect(() => {
    //     if (newRecipe && newRecipe.parts && newRecipe.parts.length > 0) {
    //         setIsButtonDisable(disabledSaveFillingButton || isNameAndDescriptionEmpty || isPartNameEmpty || isNewItemNameEmpty || isNewItemCountEmpty);
    //     }

    //     if (newRecipe && newRecipe.parts && newRecipe.parts.length === 0) {
    //         setIsButtonDisable(disabledSaveFillingButton || isNameAndDescriptionEmpty);
    //     }
    // }, [disabledSaveFillingButton, isNameAndDescriptionEmpty, isPartNameEmpty, isNewItemNameEmpty, isNewItemCountEmpty, newRecipe.parts]);

    useEffect(() => {
        setNewFilling((prev: any) => {
            return {
                ...prev,
                fillingName,
            };
        });
    }, [fillingName]);

    useEffect(() => {
        setNewFilling((prev: any) => {
            return {
                ...prev,
                recipeDescription,
            };
        });
    }, [recipeDescription]);

    return (
        <Box sx={{ ...(disabledSaveFillingButton && { pointerEvents: 'none' }) }}>
            {/* loader spinner */}
            {disabledSaveFillingButton ? (
                <Box sx={{ position: 'absolute', top: '45%', left: '45%' }}>
                    <CircularProgress size={'60px'} />
                </Box>
            ) : (
                <></>
            )}
            <Box sx={{ pt: '10px' }}>
                <Button
                    variant="outlined"
                    onClick={addItem}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: '',
                        fontWeight: '400',
                        margin: 0,
                        padding: '5px 0',
                        width: '253px',
                    }}
                >
                    {getTitle(language, FIELDS.RECIPES.ADD_NEW_ITEM)}
                </Button>
            </Box>
            {/* recipe name and description  */}
            <Box sx={{ margin: '10px', display: 'flex', flexWrap: 'wrap', ...(isMobile ? { width: '300px' } : { mt: '20px' }) }}>
                <TextField
                    focused
                    required
                    id={FIELD_NAMES.NAME}
                    label={getTitle(language, FIELDS.RECIPES.FILLING_NAME)}
                    placeholder={getTitle(language, FIELDS.RECIPES.FILLING_NAME)}
                    value={fillingName}
                    onChange={(e) => setFillingName(e.target.value)}
                    color={fillingName === '' ? 'error' : 'success'}
                    sx={{ ...(isMobile ? { width: '100%' } : { mr: '30px' }) }}
                />

                <TextField
                    focused
                    id={FIELD_NAMES.DESCRIPTION}
                    label={getTitle(language, FIELDS.RECIPES.DESCRIPTION)}
                    placeholder={getTitle(language, FIELDS.RECIPES.DESCRIPTION)}
                    multiline
                    maxRows={4}
                    value={recipeDescription}
                    onChange={(e) => setRecipeDescription(e.target.value)}
                    sx={{ ...(isMobile ? { mt: '10px', width: '100%' } : { mr: '30px' }) }}
                />

                <FormControl
                    variant="outlined"
                    size="medium"
                    disabled
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                            borderColor: 'rgb(46, 125, 50)',
                        },
                        width: '150px',
                        ...(isMobile ? { mt: '10px', width: '100%' } : {}),
                    }}
                >
                    <InputLabel id="recipe_size_label" sx={{ color: 'rgb(46, 125, 50)' }}>
                        {getTitle(language, FIELDS.ORDERS.RECIPE_PROPORTION)}
                    </InputLabel>
                    <Select
                        labelId="recipe_size_label"
                        id="PROPORTIONS"
                        name="PROPORTIONS"
                        value={proportions}
                        onChange={(e) => {
                            setProportions(e.target.value);
                        }}
                        label={getTitle(language, FIELDS.ORDERS.RECIPE_PROPORTION)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderWidth: '2px',
                                },
                                '&:hover fieldset': {
                                    borderWidth: '2px',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '2px',
                                },
                            },
                        }}
                    >
                        {RECIPE_SIZE.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Tooltip
                    placement="top"
                    title={
                        <Box>
                            <Box>{getTitle(language, FIELDS.RECIPES.DEFAULT_PROPORTION_1)}</Box>
                            <Box>{getTitle(language, FIELDS.RECIPES.DEFAULT_PROPORTION_2)}</Box>
                        </Box>
                    }
                >
                    <Box
                        sx={{
                            color: 'rgb(25, 118, 210)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            ml: '25px',
                        }}
                    >
                        <InfoIcon
                            sx={{
                                fontSize: '175%',
                                transition: 'font-size 1s',

                                ':hover': {
                                    fontSize: '275%',
                                },
                            }}
                        />
                    </Box>
                </Tooltip>
            </Box>

            {/* recipe parts */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {newFilling.items?.map((item) => (
                    <CreateRecipeItems
                        key={item.id}
                        itemId={item.id}
                        items={items}
                        fillingId={newFilling.id}
                        setNewItemValues={setNewItemValues}
                        handleDeleteItem={handleDeleteItem}
                        setIsNewItemNameEmpty={setIsNewItemNameEmpty}
                        setIsNewItemCountEmpty={setIsNewItemCountEmpty}
                        proportions={proportions}
                    />
                ))}
            </Box>

            <Box>
                <RecipeRelatives recipes={recipes} setRecipes={handleRelativeRecipe} fillingId={newFilling.id} fillingName={newFilling.fillingName} />
            </Box>

            {/* buttons */}
            <Box sx={{ mt: '10px' }}>
                <Button
                    variant="outlined"
                    disabled={disabledSaveFillingButton}
                    onClick={handleSaveFilling}
                    sx={{ ...simpleBtnStyle, margin: '10px', ...(isMobile ? { marginTop: '20px' } : {}) }}
                    endIcon={<SendIcon />}
                >
                    {getTitle(language, FIELDS.RECIPES.SAVE_FILLING)}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateFilling;
