import { ENDPOINTS } from '../common/endpoints';
import { IRecipe } from '../models/Recipe/IRecipe';
import { IRecipeLight } from '../models/Recipe/IRecipeLight';
import { baseAPI } from './BaseAPI';

// TODO: move types to modals folder
type SearchPropsType = {
    page: number;
    limit: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCost: boolean;
};

type SearchResponseType = {
    data: IRecipe[];
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
};

export const recipeAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllRecipes: build.query<IRecipe[], void>({
            query: () => ({
                url: ENDPOINTS.RECIPES.GET_ALL_RECIPES,
                method: 'GET',
            }),

            providesTags: (result) => ['Recipes'],
        }),

        getRecipe: build.query<IRecipe, string | number>({
            query: (id: string | number) => ({
                url: `${ENDPOINTS.RECIPES.GET_RECIPE}/${id}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Recipe'],
        }),

        getRecipeWithSelectedProportion: build.query<IRecipe, string[]>({
            query: (data: string[]) => ({
                url: `${ENDPOINTS.RECIPES.GET_RECIPE_WITH_SELECTED_PROPORTION}?id=${data[0]}&proportion=${data[1]}&fillingId=${data[2]}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Recipe'],
        }),

        getRecipes: build.query<SearchResponseType, SearchPropsType>({
            query: ({ page, limit, searchPhrase, categories, descending, orderByName, orderByCost }: SearchPropsType) => ({
                url: `${ENDPOINTS.RECIPES.GET_RECIPES}?page=${page}&limit=${limit}&searchPhrase=${searchPhrase}&categories=${categories}&descending=${descending}&orderByName=${orderByName}&orderByCost=${orderByCost}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Recipes'],
        }),

        saveRecipe: build.mutation<IRecipe, IRecipe>({
            query: (newRecipe: IRecipe) => ({
                url: ENDPOINTS.RECIPES.SAVE_RECIPE,
                method: 'POST',
                body: newRecipe,
            }),

            invalidatesTags: ['Recipes', 'Recipe', 'Fillings'],
        }),

        // ??? FIXME: is it correct?
        deleteRecipe: build.mutation<IRecipe, IRecipe>({
            query: (deletedRecipe: IRecipe) => ({
                url: ENDPOINTS.RECIPES.SAVE_RECIPE,
                method: 'POST',
                body: deletedRecipe,
            }),

            invalidatesTags: (result) => ['Recipes'],
        }),
        // ???

        deleteRecipes: build.mutation<void, number[]>({
            query: (deletedRecipes: number[]) => ({
                url: ENDPOINTS.RECIPES.DELETE_RECIPES,
                method: 'DELETE',
                body: deletedRecipes,
            }),

            invalidatesTags: (result) => ['Recipes'],
        }),

        getAllRecipesLight: build.query<IRecipeLight[], void>({
            query: () => ({
                url: ENDPOINTS.RECIPES.GET_ALL_RECIPES_LIGHT,
                method: 'GET',
            }),

            providesTags: (result) => ['RecipesLight'],
        }),

        getRecipesForOrder: build.query<IRecipe[], void>({
            query: () => ({
                url: ENDPOINTS.RECIPES.GET_RECIPES_FOR_ORDER,
                method: 'GET',
            }),

            providesTags: (result) => ['RecipesForOrder'],
        }),

        // createCategory: build.mutation<ICategory, string>({
        //     query: (categoryName: string) => ({
        //         url: CREATE_CATEGORY,
        //         method: 'POST',
        //         params: {
        //             categoryName: categoryName
        //         }
        //     }),

        //     invalidatesTags: ['Category'],
        // }),
    }),
});
