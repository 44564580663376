export class ENDPOINTS {
    public static BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    // Items API endpoints
    public static ITEMS = {
        GET_ALL_ITEMS: 'Items/GetAllItems',
        GET_ITEMS: 'Items/GetItems',
        GET_ITEMS_BY_CATEGORIES: 'Items/GetItemsByCategories',
        GET_ITEM_BY_ID: 'Items/GetItem/',
        CREATE_ITEM: 'Items/CreateItem',
        DELETE_ITEM: 'Items/DeleteItem/',
        ADD_ITEM_CATEGORY: 'Items/AddItemCategory',
        RENAME_ITEM: 'Items/RenameItem',
    };

    public static STOCKS = {
        ADD_STOCK: 'Stocks/AddStock',
    };

    // Recipe API endpoints
    public static RECIPES = {
        GET_ALL_RECIPES: 'Recipes/GetAllRecipes',
        CREATE_RECIPE: 'Recipes/CreateRecipe',
        SAVE_RECIPE: 'Recipes/SaveRecipe',
        GET_RECIPE: 'Recipes/GetRecipeById',
        GET_RECIPES: 'Recipes/GetRecipes',
        DELETE_RECIPES: 'Recipes/DeleteRecipes',
        GET_RECIPE_WITH_SELECTED_PROPORTION: 'Recipes/GetRecipeWithSelectedProportion',
        GET_ALL_RECIPES_LIGHT: 'Recipes/GetAllRecipesLight',
        GET_RECIPES_FOR_ORDER: 'Recipes/GetRecipesForOrder',
    };

    // Order API endpoints
    public static ORDERS = {
        SAVE_ORDER: 'Orders/SaveOrder',
        GET_ORDER_BY_ID: 'Orders/GetOrderById',
        GET_ORDER_BY_NAME: 'Orders/GetOrderByName/',
        GET_ORDERS_BY_CUSTOMER: 'Orders/GetOrdersByCustomer',
        GET_ALL_ORDERS: 'Orders/GetAllOrders',
        GET_ORDERS_SEVERAL_DAY_AHEAD: 'Orders/GetOrdersSeveralDaysAhead',
        CHANGE_ORDER_STAGE: 'Orders/ChangeOrderStage/',
        CLOSE_ORDER: 'Orders/CloseOrder',
        GET_REQUIRED_ITEMS_BY_ORDER_IDS: 'Orders/GetRequiredItemsByOrderIds',
        DELETE_ORDER: 'Orders/DeleteOrder',
        ORDER_SEARCH: 'Orders/OrderSearch',
    };

    // Filling API endpoints
    public static FILLINGS = {
        GET_ALL_FILLINGS: 'Fillings/GetAllFillings',
        GET_FILLINGS: 'Fillings/GetFillings',
        GET_FILLING: 'Fillings/GetFillingById',
        SAVE_FILLINGS: 'Fillings/SaveFilling',
        DELETE_FILLINGS: 'Fillings/DeleteFillings',
        GET_ALL_FILLINGS_LIGHT: 'Fillings/GetAllFillingsLight',
    };

    public static IMAGES = {
        SAVE_IMAGES: 'Images/SaveImages',
        GET_IMAGE: 'Images/GetImage',
    };
}

// Stock API endpoints
export const ADD_STOCK = 'Stocks/AddStock';

// Category API endpoints
export const CREATE_CATEGORY = 'Categories/CreateCategory';
export const DELETE_CATEGORY = 'Categories/DeleteCategory/';
export const GET_ALL_CATEGORIES = 'Categories/GetAllCategories';
export const GET_CATEGORY_BY_ID = 'Categories/GetCategoryById/';
export const RENAME_CATEGORY = 'Categories/RenameCategory';

// Auth API endpoints
export const LOG_IN = 'Auth/SignIn';
export const LOG_OUT = 'Auth/SignOut';

// User API endpoints
export const GET_USER_DATA = 'Users/GetUserData';
export const SAVE_USER = 'Users/SaveUser';

// Customer API endpoints
export const GET_ALL_CUSTOMERS = 'Customers/GetAllCustomers';
export const GET_CUSTOMER_BY_ID = 'Customers/CustomerById';
export const GET_CUSTOMER_BY_NAME = 'Customers/CustomerByName';
export const SEARCH_CUSTOMERS = 'Customers/SearchCustomers';
export const SAVE_CUSTOMER = 'Customers/SaveCustomer';
export const GET_CUSTOMERS = 'Customers/GetCustomers';
export const UPDATE_CUSTOMER = 'Customers/UpdateCustomer';
export const DELETE_CUSTOMER = 'Customers/DeleteCustomer';
