import { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { DTO_STATE, FIELDS, IS_DELETED, ORDER_STAGES } from '../../../common/dictionary';
import { IGroupedOrdersByDay } from '../../../models/Order/IGroupedOrdersByDay';
import { IOrder } from '../../../models/Order/IOrder';
import { OrdersByDay } from '../order/OrdersByDay';
import EditOrder from '../editOrder/EditOrder';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';

interface Props {
    orders: IGroupedOrdersByDay[];
    handleEditModeInHOC: () => void;
}

const Orders = ({ orders, handleEditModeInHOC }: Props) => {
    const { language } = useLanguage();

    const [editOrderMode, setEditOrderMode] = useState<boolean>(false);
    const [editedOrder, setEditedOrder] = useState<IOrder>({
        id: crypto.randomUUID(),
        orderNumber: '',
        description: '',
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
        price: 0,
        cost: 0,
        customerId: 0,
        subOrders: [],
        stage: ORDER_STAGES.NEW,
    });
    const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});

    // TODO: need get edited order from server
    const handleEditOrder = (order: IOrder) => {
        setEditedOrder(order);
        handleEditMode();
    };

    const handleEditMode = () => {
        setEditOrderMode(!editOrderMode);
        handleEditModeInHOC();
    };

    const deletedOrClosed = sessionStorage.getItem('deletedOrClosed');

    useEffect(() => {
        if (deletedOrClosed) {
            setRowOpen({});
        }
    }, [deletedOrClosed]);

    return (
        <Box>
            {!editOrderMode ? (
                <TableContainer>
                    <Table aria-labelledby="Orders by day">
                        <TableBody>
                            {orders?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>{getTitle(language, FIELDS.ORDERS.NO_RECORDS)}</TableCell>
                                </TableRow>
                            )}
                            {orders?.map((day, index, ordersArray) => (
                                <OrdersByDay
                                    id={index}
                                    key={day.date}
                                    ordersByDay={day}
                                    handleEditOrder={handleEditOrder}
                                    rowOpen={rowOpen}
                                    setRowOpen={setRowOpen}
                                    ordersArray={ordersArray}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                editOrderMode && <EditOrder key={editedOrder.id} order={editedOrder} handleEditMode={handleEditMode} />
            )}
        </Box>
    );
};

export default Orders;
