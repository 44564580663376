import { useEffect, useRef, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardMedia,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DTO_STATE, FIELDS } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getRecipeSize } from '../../../common/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { IOrderImage } from '../../../models/Order/IOrderImage';

interface ICreateSubOrderProps {
    subOrder: ISubOrder;
    recipes: IRecipe[] | undefined;
    handleUpdateSubOrders: (subOrder: ISubOrder) => void;
    deleteSubOrder: (id: string | number) => void;
    setFile: (files: File[]) => void;
    removeFile: (fileName: string) => void;
}

const CreateSubOrder = ({ subOrder, recipes, handleUpdateSubOrders, deleteSubOrder, setFile, removeFile }: ICreateSubOrderProps) => {
    const [newSubOrder, setNewSubOrder] = useState<ISubOrder>({ ...subOrder });
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();
    const [newPrice, setNewPrice] = useState<number>(subOrder.price);
    const [orderCount, setOrderCount] = useState<number>(1);
    const [productCost, setProductCost] = useState<number>(1);
    const [recipeCost, setRecipeCost] = useState(0);
    const [isValueSelected, setIsValueSelected] = useState(false);
    const [recipeSize, setRecipeSize] = useState<string[]>([]);
    const [selectedRecipe, setSelectedRecipe] = useState<IRecipe>();
    const inputCountRef = useRef<HTMLInputElement>(null);
    const inputPriceRef = useRef<HTMLInputElement>(null);
    const [fillingName, setFillingName] = useState('');
    const [profit, setProfit] = useState('');
    const [previewUrls, setPreviewUrls] = useState<string[] | null>(null);
    const [fileMap, setFileMap] = useState<Map<string, File>>(new Map());
    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const autoCompleteLabelStyle = { '& .MuiInputLabel-root': { pointerEvents: 'none' } };
    let recipesNameList: string[] = [];
    recipes?.map((r) => recipesNameList.push(r.recipeName || ''));

    const handleRecipeName = (e: any, name: string | null) => {
        setIsValueSelected(!!name);
        if (name) {
            recipes?.forEach((r) => {
                const recipe = r.recipeName === name;

                if (recipe) {
                    setNewSubOrder((prevState) => {
                        return {
                            ...prevState,
                            recipeName: name,
                            recipeId: r.id,
                        };
                    });

                    setSelectedRecipe(r);
                    if (r.isLinear) {
                        setRecipeSize(getRecipeSize(r));
                    } else {
                        const sizes = r.proportionInfos.map((pi) => pi.proportion);
                        setRecipeSize(sizes);
                    }

                    return;
                }
            });
        }
    };

    const handleRecipeSize = (e: any, counter: any) => {
        if (counter) {
            setNewSubOrder((prevState) => ({
                ...prevState,
                recipeCounter: counter.props.value,
            }));
        }
    };

    const handleFilling = (e: any, name: any) => {
        if (name) {
            setFillingName(name.props.value);
            const fillingId =
                selectedRecipe?.fillings && selectedRecipe?.fillings.length > 0 ? selectedRecipe.fillings.find((el) => el.fillingName === name.props.value)?.id || 0 : 0;

            setNewSubOrder((prev) => {
                return {
                    ...prev,
                    fillingId: fillingId,
                };
            });
        }
    };

    const handleOrderCount = (e: any) => {
        const qty = Number(e.target.value);

        if (qty >= 0) {
            const cost = recipeCost * newSubOrder.recipeCounter * qty;
            setOrderCount(qty);
            setNewSubOrder((prevState) => ({
                ...prevState,
                count: qty,
            }));
        }
    };

    const handlePrice = (e: any) => {
        const price = Number(e.target.value);

        if (price >= 0) {
            setNewPrice(price);
            setNewSubOrder((prevState) => ({
                ...prevState,
                price,
            }));
        }
    };

    const size =
        newSubOrder.recipeCounter.toString().length === 1 && newSubOrder.recipeCounter !== 0
            ? `${newSubOrder.recipeCounter}.00`
            : newSubOrder.recipeCounter.toString().length === 3
            ? `${newSubOrder.recipeCounter}0`
            : newSubOrder.recipeCounter === 0
            ? ''
            : newSubOrder.recipeCounter.toString() || '1.00';

    const recipeCounterError = newSubOrder.recipeCounter === 0;
    const fillingError = newSubOrder.fillingId === 0;
    const calculateProductCost = () => {
        const hasFillings = selectedRecipe?.fillings && selectedRecipe?.fillings.length > 0;
        const fillingId = hasFillings && newSubOrder.fillingId !== 0 ? selectedRecipe.fillings.find((el) => el.id === newSubOrder.fillingId)?.id || 0 : 0;
        const recipeCost = selectedRecipe?.proportionInfos && selectedRecipe?.proportionInfos.length > 0 ? Number(selectedRecipe?.proportionInfos[0].cost) : 0;
        const fillingCost = hasFillings ? Number(selectedRecipe?.fillings.find((el) => el.id === fillingId)?.proportionInfos[0].cost || 0) : 0;
        const tempCost = (fillingCost + recipeCost) * newSubOrder.count * newSubOrder.recipeCounter;
        setProductCost(tempCost);
        setNewSubOrder((prev) => {
            return {
                ...prev,
                productCost: tempCost,
            };
        });
        setRecipeCost(tempCost);
    };

    const handleRemoveImage = (previewUrl: string) => {
        const originalFileName = urlMap.get(previewUrl);

        if (originalFileName) {
            setPreviewUrls((prevUrls) => prevUrls && prevUrls.filter((url) => url !== previewUrl));

            setFileMap((prevMap) => {
                const newMap = new Map(prevMap);
                newMap.delete(originalFileName);
                return newMap;
            });

            setUrlMap((prevMap) => {
                const newMap = new Map(prevMap);
                newMap.delete(previewUrl);
                return newMap;
            });

            removeFile(fileMap.get(originalFileName)?.name || '');
        }
    };

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            // Check duplicates
            const filteredFiles = files.filter((file) => {
                if (!fileMap.has(file.name)) {
                    return true;
                }
                return false;
            });

            const newUrlMap = new Map(urlMap);
            const updatedPreviewUrls: string[] = [];

            const newFileMap = new Map(fileMap);
            filteredFiles.forEach((file) => {
                const renamedFile = new File([file], crypto.randomUUID(), {
                    type: file.type,
                    lastModified: file.lastModified,
                });

                const previewUrl = URL.createObjectURL(renamedFile);
                newFileMap.set(file.name, renamedFile);
                newUrlMap.set(previewUrl, file.name);
                updatedPreviewUrls.push(previewUrl);
            });
            setPreviewUrls((prevFiles) => [...(prevFiles || []), ...updatedPreviewUrls]);
            setFileMap(newFileMap);
            setUrlMap(newUrlMap);
            // Clear the input value to allow the browser to select the same files again
            e.target.value = '';
        }
    };

    useEffect(() => {
        const files = Array.from(fileMap.values());
        const images: IOrderImage[] = files.map((el) => {
            return { id: el.name, subOrderId: newSubOrder.id, orderId: newSubOrder.orderId, fileName: el.name, isResultImage: false, state: DTO_STATE.DTO_NEW };
        });
        const subOrderImages = newSubOrder.images?.length || 0;

        setNewSubOrder((prev) => {
            return {
                ...prev,
                images: images,
            };
        });

        if (images.length > subOrderImages) {
            setFile(files);
        }
    }, [fileMap]);

    useEffect(() => {
        if (newSubOrder.fillingId !== 0 && (!selectedRecipe?.fillings || selectedRecipe.fillings.length == 0)) {
            setFillingName('');
            setNewSubOrder((prev) => {
                return {
                    ...prev,
                    fillingId: 0,
                    fillingName: '',
                };
            });
        }

        handleUpdateSubOrders(newSubOrder);
    }, [newSubOrder]);

    useEffect(() => {
        calculateProductCost();
        setProfit(Number(newSubOrder.price - newSubOrder.productCost).toFixed(2));
    }, [newPrice, productCost, size, orderCount]);

    return (
        <Box sx={{ padding: '20px', backgroundColor: 'rgba(211, 211, 211, 0.5)', borderTop: '1px solid #999', borderBottom: '1px solid #999', mt: '10px', mb: '10px' }}>
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 390 }}>
                <Autocomplete
                    disablePortal
                    id="recipes"
                    placeholder={getTitle(language, FIELDS.ORDERS.SELECT_RECIPE)}
                    options={recipesNameList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={getTitle(language, FIELDS.ORDERS.SELECT_RECIPE)}
                            color={newSubOrder.recipeName === '' ? 'error' : 'success'}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&:hover fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                },
                            }}
                        />
                    )}
                    onChange={handleRecipeName}
                    sx={{
                        ...autoCompleteLabelStyle,
                        '&.MuiAutocomplete-root .MuiInputLabel-root': {
                            color: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                        },
                    }}
                />
                <FormControl
                    variant="outlined"
                    size="medium"
                    required
                    error={recipeCounterError}
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                            borderColor: recipeCounterError ? 'red' : 'green',
                        },
                    }}
                >
                    <InputLabel id="recipe_size_label">{getTitle(language, FIELDS.ORDERS.RECIPE_SIZE_KG)}</InputLabel>
                    <Select
                        labelId="recipe_size_label"
                        id="create_recipe_select"
                        value={size}
                        onChange={handleRecipeSize}
                        label={getTitle(language, FIELDS.ORDERS.RECIPE_SIZE_KG)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&:hover fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                            },
                        }}
                    >
                        {recipeSize.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedRecipe?.fillings && selectedRecipe.fillings.length > 0 && (
                    <FormControl
                        variant="outlined"
                        size="medium"
                        required
                        error={fillingError}
                        sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '2px',
                                borderColor: fillingError ? 'red' : 'green',
                            },
                        }}
                    >
                        <InputLabel id="filling_label">{getTitle(language, FIELDS.RECIPES.FILLING)}</InputLabel>
                        <Select
                            labelId="filling_label"
                            id="filling_select"
                            value={fillingName}
                            onChange={handleFilling}
                            label={getTitle(language, FIELDS.RECIPES.FILLING)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&:hover fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                },
                            }}
                        >
                            {selectedRecipe.fillings.map((option) => (
                                <MenuItem key={option.fillingName} value={option.fillingName}>
                                    {option.fillingName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <TextField
                    inputRef={inputCountRef}
                    required
                    id={'count'}
                    label={getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}
                    placeholder={getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}
                    value={orderCount}
                    focused
                    type="number"
                    onChange={handleOrderCount}
                    onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                    color={orderCount === 0 ? 'error' : 'success'}
                    style={{ marginBottom: 10 }}
                    sx={autoCompleteLabelStyle}
                />
                <TextField
                    inputRef={inputPriceRef}
                    required
                    id={'price'}
                    label={getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                    placeholder={getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                    value={newPrice}
                    focused
                    type="number"
                    onChange={handlePrice}
                    onFocus={() => inputPriceRef.current && inputPriceRef.current.select()}
                    color={newSubOrder.price === 0 ? 'error' : 'success'}
                    style={{ marginBottom: 10 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                    }}
                    sx={autoCompleteLabelStyle}
                />

                <input style={{ display: 'none' }} id={`file-upload_${subOrder.id}`} type="file" multiple accept=".jpg,.png,.jpeg" onChange={uploadFile} />

                <label htmlFor={`file-upload_${subOrder.id}`}>
                    <Button variant="contained" component="span">
                        {getTitle(language, FIELDS.ORDERS.UPLOAD_TARGET_IMAGES)}
                    </Button>
                </label>

                {previewUrls && (
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {previewUrls.map((url, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ maxWidth: 345, position: 'relative' }}>
                                    <IconButton
                                        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                                        onClick={() => handleRemoveImage(url || '')}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <CardMedia component="img" height="200" image={url} alt={`Preview ${index + 1}`} />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}

                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_PRICE)} {Number(newPrice * newSubOrder.count).toFixed(2)} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_COST)} {Number(newSubOrder.productCost).toFixed(2)} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_PROFIT)} {profit} zł
                </Box>
                <Button variant="outlined" onClick={() => deleteSubOrder(subOrder.id)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, FIELDS.ORDERS.DELETE_SUBORDER)}
                </Button>
            </Stack>
        </Box>
    );
};

export default CreateSubOrder;
