import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Grid, ListItem, TextField } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { IItem } from '../../../../models/Items/IItem';
import getTitle from '../../../../common/translations';
import { useLanguage } from '../../../../context/LanguageContext';
import { DTO_STATE, FIELDS } from '../../../../common/dictionary';
import { getStyles } from '../../recipeCommon';
import { getMedia } from '../../../../common/mediaQuery';

interface Props {
    item: IRecipePartItem;
    items: IItem[] | undefined;
    saveChanges: (editedItem: IRecipePartItem) => void;
    isPartDeleted?: boolean;
    isLast: boolean;
    isDuplicateName?: boolean;
}

export const EditFillingItem = ({ item, items, saveChanges, isPartDeleted, isLast, isDuplicateName }: Props) => {
    const { partsInputStyle, autoCompleteLabelStyle } = getStyles();
    const { isTablet } = getMedia();

    const qty = item.proportions[0].quantity;

    const backgroundColor = item.state === DTO_STATE.DTO_DELETED || isPartDeleted ? '#FF7F7F' : '';
    const { language } = useLanguage();
    const [newName] = useState<string | undefined>(item.itemName || undefined);
    const [newCount, setNewCount] = useState(qty);
    const [editedItem, setEditedItem] = useState<IRecipePartItem>({ ...item });
    const [lastState, setLastState] = useState<string>(editedItem.state);
    const [bgcC, setBgcC] = useState('transparent');
    const [bgcN, setBgcN] = useState('transparent');
    const [forceEditedItemUpdate, setForceEditedItemUpdate] = useState(false);

    const inputCountRef = useRef<HTMLInputElement>(null);

    let itemNameList: string[] = [];
    items?.map((item) => itemNameList.push(item.name));

    const changeItem = (e: any) => {
        const localData = Number(e.target.value);

        if (localData > 0) {
            if (qty !== localData) {
                setBgcC('rgba(233, 255, 77,0.5)');
            }

            setNewCount(localData);

            setEditedItem((prev) => {
                const updatedProportions = prev.proportions.map((item: any, index: any) =>
                    index === 0 ? { ...item, quantity: localData, state: item.state === DTO_STATE.DTO_NEW ? item.state : DTO_STATE.DTO_MODIFIED } : item
                );

                return {
                    ...prev,
                    proportions: updatedProportions,
                };
            });

            setEditedItem((prev) => {
                if (prev.state !== DTO_STATE.DTO_NEW) {
                    return { ...prev, state: DTO_STATE.DTO_MODIFIED };
                }
                return prev;
            });

            setForceEditedItemUpdate(!forceEditedItemUpdate);
        }
    };

    useEffect(() => {
        saveChanges(editedItem);
    }, [forceEditedItemUpdate]);

    const handleItemName = (e: any, name: string | null) => {
        const localData = e.target.value;

        if (item.itemName !== localData) {
            setBgcN('rgba(233, 255, 77,0.5)');
        }

        saveChanges(editedItem);

        const itemId = items?.find((item) => item.name === name)?.id || -1;
        const itemUnitOfWeight = items?.find((item) => item.name === name)?.unitOfWeight || '';

        editedItem.itemName = name || '';
        editedItem.itemId = itemId;
        editedItem.unitOfWeight = itemUnitOfWeight;

        if (editedItem.state !== DTO_STATE.DTO_NEW) {
            editedItem.state = DTO_STATE.DTO_MODIFIED;
        }
    };

    const handleDelete = () => {
        if (editedItem.state === DTO_STATE.DTO_DELETED) {
            // restore
            editedItem.state = lastState;
        } else {
            // delete
            setLastState(editedItem.state);
            editedItem.state = DTO_STATE.DTO_DELETED;
        }
        saveChanges(editedItem);
    };

    useEffect(() => {
        if (item.state === DTO_STATE.DTO_NEW) {
            setBgcN('rgba(233, 255, 77, 0.25)');
            setBgcC('rgba(233, 255, 77, 0.25)');
        }
    }, []);

    return (
        <Box sx={{ backgroundColor: backgroundColor }}>
            <Grid
                container
                spacing={1}
                sx={{
                    height: '55.6px',
                    mt: 0,
                    width: '100%',
                    borderBottom: isLast ? '0.1px solid rgba(0, 0, 0, 0.1)' : '0.1px solid rgba(0, 0, 0, 0)',
                    ...(isDuplicateName ? { backgroundColor: 'rgba(255, 140, 0, 0.25)' } : {}),
                }}
            >
                <Grid
                    item
                    xs={6}
                    sx={{
                        pt: '0 !important',
                        ...(isTablet
                            ? {
                                  pl: '5px !important',
                                  maxWidth: '50%',
                              }
                            : {}),
                    }}
                >
                    <Autocomplete
                        size="small"
                        disablePortal
                        //disabled={item.state === DTO_STATE.DTO_DELETED || isPartDeleted || isNewProportion}
                        disabled={item.state === DTO_STATE.DTO_DELETED || isPartDeleted}
                        id="itemName"
                        placeholder={getTitle(language, FIELDS.RECIPES.ITEM_NAME_RECIPES)}
                        options={itemNameList}
                        defaultValue={newName}
                        disableClearable={isTablet}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={getTitle(language, FIELDS.RECIPES.ITEM_NAME_RECIPES)}
                                required
                                label={getTitle(language, FIELDS.RECIPES.ITEM_NAME_RECIPES)}
                                variant={'filled'}
                                sx={{
                                    ...partsInputStyle,
                                    backgroundColor: bgcN,
                                    ...autoCompleteLabelStyle,

                                    ...(isTablet
                                        ? {
                                              '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                              '& .MuiFilledInput-root': { paddingRight: '15px !important' },
                                              '& .MuiAutocomplete-endAdornment': {
                                                  right: '-3px !important',
                                                  top: '17px !important',
                                              },
                                              width: '100%',
                                          }
                                        : {}),
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <ListItem {...props} sx={isTablet ? { fontSize: '0.8rem', minHeight: '25px !important' } : {}}>
                                {option}
                            </ListItem>
                        )}
                        onChange={handleItemName}
                        sx={autoCompleteLabelStyle}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        pt: '0 !important',
                        display: 'flex',
                        alignItems: 'baseline',

                        ...(isTablet ? { ml: '0px', mr: '0px' } : { ml: '25px', mr: '25px', maxWidth: '24% !important' }),
                    }}
                >
                    <TextField
                        inputRef={inputCountRef}
                        size="small"
                        required
                        disabled={item.state === DTO_STATE.DTO_DELETED || isPartDeleted}
                        id="count"
                        label={getTitle(language, FIELDS.RECIPES.COUNT_RECIPES)}
                        placeholder={getTitle(language, FIELDS.RECIPES.COUNT_RECIPES)}
                        type="number"
                        value={newCount}
                        onChange={changeItem}
                        onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                        variant={'filled'}
                        sx={{
                            ...partsInputStyle,
                            backgroundColor: bgcC,
                            width: '150px',
                            ...(isTablet
                                ? {
                                      '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                  }
                                : {}),
                        }}
                    />
                    &nbsp;
                    <Box sx={{ minWidth: '30px' }}>{editedItem.unitOfWeight}</Box>
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        pt: '0 !important',
                        display: 'flex',
                        alignItems: 'center',

                        ...(isTablet
                            ? {
                                  pl: '4px !important',
                              }
                            : {}),
                    }}
                >
                    <Button
                        onClick={handleDelete}
                        //disabled={isPartDeleted || isNewProportion}
                        disabled={isPartDeleted}
                        sx={{
                            color: editedItem.state === DTO_STATE.DTO_DELETED ? 'rgba(30, 75, 30, 1)' : 'rgba(255,255,255, 0.9)',
                            backgroundColor: editedItem.state === DTO_STATE.DTO_DELETED && !isPartDeleted ? 'rgba(0, 255, 0, 0.5)' : 'rgba(150, 0, 0, 0.7)',
                            padding: '4px',
                            border: '1px solid rgba(150, 0, 0, 0.7) !important',

                            ':hover': {
                                backgroundColor: '#fff',
                                color: 'rgba(150, 0, 0, 0.7)',
                            },

                            ...(isTablet
                                ? {
                                      fontSize: '0.8rem !important',
                                      minWidth: '40px',
                                  }
                                : {}),
                        }}
                    >
                        {editedItem.state === DTO_STATE.DTO_DELETED && !isPartDeleted ? (
                            isTablet ? (
                                <UndoIcon />
                            ) : (
                                getTitle(language, FIELDS.RECIPES.RESTORE_I)
                            )
                        ) : isTablet ? (
                            <DeleteOutlineIcon />
                        ) : (
                            getTitle(language, FIELDS.RECIPES.DELETE_I)
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
