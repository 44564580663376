import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import RecipePart from './RecipePart';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getMedia } from '../../../common/mediaQuery';
import { getStyles } from '../recipeCommon';
import Filling from '../filling/Filling';
import { IFilling } from '../../../models/Recipe/Filling/IFilling';
import { FIELDS } from '../../../common/dictionary';
import FillingRecipe from '../filling/FillingRecipe';

interface RecipeProps {
    recipe: IRecipe;
    openedRow: boolean;
    isLoading: boolean;
    size: number;
    setFillingPrice: (filling: IFilling | undefined, price: string) => void;
    selectedFilling: IFilling | undefined;
    editOpen: boolean;
}

const Recipe = ({ recipe, openedRow, isLoading, size, setFillingPrice, selectedFilling, editOpen }: RecipeProps) => {
    const { language } = useLanguage();
    const { opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();
    const fillings = recipe.fillings?.map((el) => el.fillingName ?? '');

    const setFilling = (name: string) => {
        const filling = recipe.fillings.find((el) => el.fillingName === name);
        if (filling) {
            const price = filling?.proportionInfos && filling?.proportionInfos.length > 0 ? filling?.proportionInfos[0].cost : '0.00';
            setFillingPrice(filling, price);
        }
    };

    return (
        <Box sx={{ flexBasis: isTablet ? '100%' : '39%', position: 'relative' }}>
            <Box sx={isLoading ? opacityHalf : opacityFull}>
                <Grid item xs={12} sx={{ mt: '5px', mb: '20px' }}>
                    <Paper sx={{ boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.2)' }}>
                        <Table sx={{ backgroundColor: '#fafaf8' }}>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, 'recipe')}: &nbsp;
                                        {recipe.recipeName}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, 'description')}: &nbsp;
                                        {recipe.recipeDescription}
                                    </TableCell>
                                </TableRow>
                                {recipe.parts?.map((part, index: number) => (
                                    <TableRow
                                        key={part.id}
                                        sx={{
                                            height: '55px',
                                        }}
                                    >
                                        <RecipePart recipePart={part} index={index} openedRow={openedRow} isLoading={isLoading} size={size} isLinear={recipe.isLinear} />
                                    </TableRow>
                                ))}
                                {recipe.fillings.length > 0 && !editOpen && (
                                    <>
                                        <TableRow
                                            sx={{
                                                height: '55px',
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    minWidth: '235px',
                                                    maxWidth: '1082px',
                                                }}
                                            >
                                                Начинки
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{
                                                height: '55px',
                                            }}
                                        >
                                            {/* <TableRow> */}
                                            <TableCell
                                                sx={{
                                                    minWidth: '235px',
                                                    maxWidth: '1082px',
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="fillings_label">{getTitle(language, FIELDS.RECIPES.FILLING)}</InputLabel>
                                                    <Select
                                                        labelId="fillings_label"
                                                        id="Fillings"
                                                        name="Fillings"
                                                        value={selectedFilling?.fillingName ?? ''}
                                                        onChange={(e) => {
                                                            setFilling(e.target.value);
                                                        }}
                                                        label={getTitle(language, FIELDS.RECIPES.FILLING)}
                                                    >
                                                        {fillings.map((option, index) => (
                                                            <MenuItem key={option + index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {selectedFilling && (
                                            <TableRow
                                                sx={{
                                                    height: '55px',
                                                }}
                                            >
                                                <TableCell
                                                    sx={{
                                                        minWidth: '235px',
                                                        maxWidth: '1082px',
                                                    }}
                                                >
                                                    <Filling filling={selectedFilling} isLoading={isLoading} size={size} openedRow={openedRow} viewInRecipe={true} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                )}
                                {editOpen && recipe.recipeFillings.length > 0 && (
                                    <TableRow>
                                        <TableCell sx={{ pl: '16px', pt: '10px', pb: '10px' }}>
                                            {getTitle(language, FIELDS.NAV_BAR.RECIPES_FILLINGS)}:
                                            <Box sx={{ pl: '10px', pt: '10px' }}>
                                                {recipe.recipeFillings?.map((part: any, index: number) => (
                                                    <FillingRecipe key={index} part={part} length={recipe.recipeFillings.length} index={index} />
                                                ))}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Box>
        </Box>
    );
};
export default Recipe;
