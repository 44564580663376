import { fillingAPI } from '../../services/FillingServices';
import { itemAPI } from '../../services/ItemServices';
import { recipeAPI } from '../../services/RecipeServices';

export function fetchRecipes({
    currentPageNumber,
    itemsPerPage,
    searchPhrase,
    categories,
    descending,
    orderByName,
    orderByCost,
}: {
    currentPageNumber: number;
    itemsPerPage: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCost: boolean;
}) {
    const {
        data: recipes,
        isLoading: getAllItemsIsLoading,
        error: getAllItemsError,
        isSuccess: getAllItemsIsSuccess,
    } = recipeAPI.useGetRecipesQuery({
        page: currentPageNumber,
        limit: itemsPerPage,
        searchPhrase,
        categories,
        descending,
        orderByName,
        orderByCost,
    });

    return {
        recipes,
        getAllItemsIsLoading,
        getAllItemsError,
        getAllItemsIsSuccess,
    };
}

export function getAllItems() {
    const { data: items, error: getAllItemsError, isLoading: getAllItemsIsLoading, isSuccess: getAllItemsIsSuccess } = itemAPI.useGetAllItemsQuery();

    return {
        items,
        getAllItemsIsLoading,
        getAllItemsError,
        getAllItemsIsSuccess,
    };
}

export function getRecipe(recipeId: string | number, isLinear: boolean, size: string) {
    const { data: recipeData, isLoading: isLoadingRecipe, error: getRecipeError, isSuccess: isSuccessRecipe } = recipeAPI.useGetRecipeQuery(recipeId);

    return {
        recipeData,
        isLoadingRecipe,
        getRecipeError,
        isSuccessRecipe,
    };
}

export function getRecipesLight() {
    const { data: recipes, isLoading: isLoadingRecipe, error: getRecipeError, isSuccess: isSuccessRecipe } = recipeAPI.useGetAllRecipesLightQuery();

    return {
        recipes,
        isLoadingRecipe,
        getRecipeError,
        isSuccessRecipe,
    };
}

export function saveRecipePost() {
    const [saveRecipe, { error: saveRecipeError, isSuccess: isSaveRecipeSuccess }] = recipeAPI.useSaveRecipeMutation();

    return { saveRecipe, saveRecipeError, isSaveRecipeSuccess };
}

export function fetchFillings({
    currentPageNumber,
    itemsPerPage,
    searchPhrase,
    categories,
    descending,
    orderByName,
    orderByCost,
}: {
    currentPageNumber: number;
    itemsPerPage: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCost: boolean;
}) {
    const {
        data: fillings,
        isLoading: getAllFillingsIsLoading,
        error: getAllFillingsError,
        isSuccess: getAllFillingsIsSuccess,
    } = fillingAPI.useGetFillingsQuery({
        page: currentPageNumber,
        limit: itemsPerPage,
        searchPhrase,
        categories,
        descending,
        orderByName,
        orderByCost,
    });

    return {
        fillings,
        getAllFillingsIsLoading,
        getAllFillingsError,
        getAllFillingsIsSuccess,
    };
}

export function saveFillingPost() {
    const [saveFilling, { error: saveFillingError, isSuccess: isSaveFillingSuccess }] = fillingAPI.useSaveFillingMutation();

    return { saveFilling, saveFillingError, isSaveFillingSuccess };
}

export function getFilling(fillingId: string | number) {
    const { data: fillingData, isLoading: isLoadingFilling, error: getFillingError, isSuccess: isSuccessFilling } = fillingAPI.useGetFillingQuery(fillingId);

    return {
        fillingData,
        isLoadingFilling,
        getFillingError,
        isSuccessFilling,
    };
}

export function getFillingsLight() {
    const { data: fillings, isLoading: isLoadingFillings, error: getFillingsError, isSuccess: isSuccessFillings } = fillingAPI.useGetAllFillingsLightQuery();

    return {
        fillings,
        isLoadingFillings,
        getFillingsError,
        isSuccessFillings,
    };
}
