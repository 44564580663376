export class DTO_STATE {
    public static DTO_NEW = 'DTO_NEW';
    public static DTO_MODIFIED = 'DTO_MODIFIED';
    public static DTO_DELETED = 'DTO_DELETED';
    public static DTO_UNCHANGED = 'DTO_UNCHANGED';
}

export enum IS_DELETED {
    ACTIVE,
    IN_ACTIVE,
}

export enum UNIT_OF_WEIGHT {
    PCS = 'PCS',
    G = 'G',
}

export enum FIELD_NAMES {
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
}

export enum ORDER_STAGES {
    NEW,
    PREPAID,
    CANCELED,
    DONE,
    PICKED_UP,
}

export const ORDER_SOURCES = ['INSTAGRAM', 'FACEBOOK', 'WHATSAPP', 'PHONE'];

export const RECIPE_SIZE = [
    '0.25',
    '0.50',
    '0.75',
    '1.00',
    '1.25',
    '1.50',
    '1.75',
    '2.00',
    '2.25',
    '2.50',
    '2.75',
    '3.00',
    '3.25',
    '3.50',
    '3.75',
    '4.00',
    '4.25',
    '4.50',
    '4.75',
    '5.00',
    // '5.25',
    // '5.50',
    // '5.75',
    // '6.00',
    // '6.25',
    // '6.50',
    // '6.75',
    // '7.00',
    // '7.25',
    // '7.50',
    // '7.75',
    // '8.00',
    // '8.25',
    // '8.50',
    // '8.75',
    // '9.00',
    // '9.25',
    // '9.50',
    // '9.75',
    // '10.00',
];

export const RECIPE_SIZE_SPECIAL = [
    '0.25',
    '0.33',
    '0.50',
    '0.66',
    '0.75',
    '1.00',
    '1.25',
    '1.33',
    '1.50',
    '1.66',
    '1.75',
    '2.00',
    '2.25',
    '2.50',
    '2.75',
    '3.00',
    '3.25',
    '3.50',
    '3.75',
    '4.00',
    '4.25',
    '4.50',
    '4.75',
    '5.00',
    // '5.25',
    // '5.50',
    // '5.75',
    // '6.00',
    // '6.25',
    // '6.50',
    // '6.75',
    // '7.00',
    // '7.25',
    // '7.50',
    // '7.75',
    // '8.00',
    // '8.25',
    // '8.50',
    // '8.75',
    // '9.00',
    // '9.25',
    // '9.50',
    // '9.75',
    // '10.00',
];

export const CLEAR_BUTTON = 'InputAdornment-IconButton-SearchOffIcon-ff#11@!';

export class FIELDS {
    public static COMMON = {
        CANCEL: 'cancel',
        CONFIRM: 'confirm',
        CREATE: 'create',
        YES: 'yes',
        NO: 'no',
        SOCIAL_MEDIA_PROFILE: 'social_media_profile',
        AT: 'at',
        ALREADY_EXISTS: 'already_exists',
        SAVE: 'save',
        DELETE: 'delete',
        SEARCH: 'search',
    };
    public static ITEMS = {
        CREATE_ITEM: 'createItem',
        ITEM_NAME: 'item_name',
        WEIGHT: 'weight',
        PRICE: 'price',
        RECIPE_QTY: 'recipeQty',
        PCS: 'pcs',
        PCS_TITLE: 'pcs_Title',
        COUNT: 'count',
        CATEGORIES: 'categories',
        CREATE_NEW_CATEGORY: 'create_new_category',
        CREATE_NEW_ITEM: 'create_new_item',
        CREATE_NEW_ITEM_DIALOG_CONT: 'create_new_item_dialog_cont',
        CREATE_CATEGORY: 'create_category',
        CATEGORY_NAME: 'category_name',
        UNIT_OF_WEIGHT: 'unit_of_weight',
        MODIFIED_ITEM: 'modified_item',
        RENAME_ITEM: 'rename_item',
        RENAME_ITEM_DIALOG_CONT: 'rename_item_dialog_cont',
        MODIFY_CATEGORIES: 'modify_categories',
        ADD_OR_DELETE_CATEGORIES: 'add_or_delete_categories',
        ADD_STOCK: 'add_stock',
        WEIGHT_OF_PIECE: 'weight_of_piece',
        DELETE_ITEM: 'delete_item',
        DELETE_ITEM_INFO_START: 'delete_item_info_start',
        DELETE_ITEM_INFO_START_MOBILE: 'delete_item_info_start_mobile',
        NUM_SELECTED: 'numSelected',
        BACK_TO_ITEMS: 'back_to_Items',
        LOGIN_TITLE: 'loginTitle',
        LOGIN_BTN: 'loginBtn',
        EMAIL_HOLDER: 'emailHolder',
        PSW_HOLDER: 'pswHolder',
        LOGIN_ERROR: 'loginError',
        SERVER_ERROR: 'serverError',
    };
    public static MENU = {
        PROFILE: 'profile',
        ORDERS: 'orders',
        DELIVERY: 'delivery',
        STOCK: 'stock',
        FINANCE: 'finance',
        SETTINGS: 'settings',
        LOGOUT: 'logout',
    };
    public static RECIPES = {
        BACK_TO_RECIPES: 'back_to_recipes',
        CREATE_RECIPE: 'create_recipe',
        BACK_TO_FILLINGS: 'back_to_fillings',
        CREATE_FILLING: 'create_filling',
        CREATE_NON_LINEAR_RECIPE: 'create_non_linear_recipe',
        DESCRIPTION: 'description',
        RECIPE_COST: 'recipe_cost',
        RECIPE_NAME: 'recipe_name',
        SAVE_RECIPE: 'save_recipe',
        SAVE_RECIPE_SHORT: 'save_recipe_short',
        ADD_PART: 'add_part',
        ADD_PART_DEFAULT: 'add_part_default',
        ADD_PROPORTION: 'add_proportion',
        ADD_PROPORTION_SHORT: 'add_proportion_short',
        DEFAULT_PROPORTION_1: 'default_proportion_1',
        DEFAULT_PROPORTION_2: 'default_proportion_2',
        DELETE_PART: 'delete_part',
        PART_NAME: 'part_name',
        ADDITIONAL_INFO: 'additional_info',
        ADD_ADDITIONAL_INFO: 'add_additional_info',
        ADD_NEW_ITEM: 'add_new_item',
        ITEM_NAME_RECIPES: 'item_name_recipes',
        COUNT_RECIPES: 'count_recipes',
        DELETE_ITEM_PART: 'delete_item_part',
        RESTORE_ITEM_PART: 'restore_item_part',
        ADD_ITEM_PART: 'add_item_part',
        ADD_ITEM_PART_SHORT: 'add_item_part_short',
        SERVICE_SIZE: 'service_size',
        SERVICE_PRICE: 'service_price',
        EDIT_RECIPE: 'edit_recipe',
        CANCEL_EDITING: 'cancel_editing',
        PART: 'part',
        RECIPE: 'recipe',
        RESTORE_I: 'restore_i',
        DELETE_I: 'delete_i',
        LINEAR_RECIPE: 'linear_recipe',
        NONLINEAR_RECIPE: 'nonlinear_recipe',
        DELETE_TITLE: 'delete_title',
        CONFIRM_DELETION: 'confirm_deletion',
        USED_IN_RECIPES: 'used_in_recipes',
        USED_IN_FILLINGS: 'used_in_fillings',
        FILLING: 'filling',
        EDIT_FILLING: 'edit_filling',
        ADD_RELATIVE_RECIPE: 'add_relative_recipe',
        SAVE_FILLING: 'save_filling',
        RELATIVE_RECIPES: 'relative_recipes',
        FILLING_NAME: 'filling_name',
    };
    public static ORDERS = {
        CREATE_ORDER: 'create_order',
        DELETE_ORDER: 'delete_order',
        DELETE_ORDER_MESSAGE: 'delete_order_message',
        RECEIPT_ISSUED: 'receipt_issued',
        BACK_TO_ORDER_LIST: 'back_to_order_list',
        ORDER_NUMBER: 'order_number',
        PRICE_ORDERS: 'price_orders',
        DESCRIPTION_ORDERS: 'description_orders',
        PREPAYMENT: 'prepayment',
        WAITING: 'waiting',
        RECEIVED: 'received',
        ORDER_SOURCE: 'order_source',
        CUSTOMER: 'customer',
        CUSTOMER_INFO: 'customer_info',
        MORE_INFO: 'more_info',
        PICK_UP_DATE: 'pick_up_date',
        PICK_UP_TIME: 'pick_up_time',
        REST_FOR_PAYMENT: 'rest_for_payment',
        CLOSE: 'close',
        SELECT: 'select',
        EDIT: 'edit',
        ORDER_DONE: 'order_done',
        PICKED_UP: 'picked_up',
        ORDER_DESCRIPTION: 'order_description',
        SEARCH_CUSTOMER: 'search_customer',
        CUSTOMERS: 'customers',
        FOUND_CUSTOMERS: 'foundCustomers',
        CREATE_CUSTOMER: 'create_customer',
        SELECTED_CUSTOMER: 'selected_customer',
        NO_CUSTOMER: 'no_customer',
        CUSTOMER_NOT_FOUND: 'customer_not_found',
        ADD_SUBORDER: 'add_suborder',
        DELETE_SUBORDER: 'delete_suborder',
        SUB_ORDERS: 'sub_orders',
        EDIT_SUB_ORDERS: 'edit_sub_orders',
        TOTAL_ORDER_PRICE: 'total_order_price',
        TOTAL_ORDER_COST: 'total_order_cost',
        TOTAL_ORDER_PROFIT: 'total_order_profit',
        ORDER_PRICE: 'order_price',
        ORDER_COST: 'order_cost',
        ORDER_PROFIT: 'order_profit',
        SAVE_ORDER: 'save_order',
        SELECT_RECIPE: 'select_recipe',
        RECIPE_SIZE_KG: 'recipe_size_kg',
        RECIPE_PROPORTION: 'recipe_proportion',
        COUNT_ORDERS: 'count_orders',
        BACK_TO_ORDERS: 'back_to_orders',
        PREPAYMENT_BALANCE: 'prepayment_balance',
        PREPAYMENT_AT: 'prepayment_at',
        CHANGE_PICKUP_DATE: 'change_pickup_date',
        CHANGE_PREPAYMENT: 'change_prepayment',
        RECIPE_TITLE: 'recipe_title',
        FILLING_TITLE: 'filling_title',
        FULL_NAME: 'full_name',
        EMAIL: 'email',
        PHONE_NUMBER: 'phone_number',
        INSTAGRAM: 'instagram',
        FACEBOOK: 'facebook',
        REQUIRED_INGREDIENTS: 'required_ingredients',
        SELECT_DATES_FOR_THE_SEARCH: 'select_dates_for_the_search',
        SELECT_DATE_RANGE_FOR_THE_SEARCH: 'select_date_range_for_the_search',
        OPEN_CALENDAR: 'open_calendar',
        CLOSED_ORDERS: 'closed_orders',
        RANGE_SEARCH: 'range_search',
        MULTIPLE_DATES_SEARCH: 'multiple_dates_search',
        SEARCH_OPTIONS: 'search_options',
        AN_ORDER_FOR_THE_CUSTOMER: 'an_order_for_the_customer',
        SHOW_ORDER: 'show_order',
        SUNDAY: 'sunday',
        MONDAY: 'monday',
        TUESDAY: 'tuesday',
        WEDNESDAY: 'wednesday',
        THURSDAY: 'thursday',
        FRIDAY: 'friday',
        SATURDAY: 'saturday',
        SEARCHED_ORDER: 'searchedOrder',
        NO_RECORDS: 'no_records',
        UPLOAD_TARGET_IMAGES: 'upload_target_images',
    };
    public static CUSTOMERS = {
        CUSTOMER_NAME: 'customer_name',
        CUSTOMER_QTY: 'customer_qty',
        BACK_TO_CUSTOMERS: 'back_to_customers',
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        PHONE_NUMBER: 'phoneNumber',
    };
    public static NAV_BAR = {
        FINANCES: 'finances',
        RECIPES: 'recipes',
        RECIPES_FILLINGS: 'recipes_fillings',
        RECIPES_DECORATIONS: 'recipes_decorations',
        ITEMS: 'items',
        CUSTOMERS_NAVBAR: 'customers_navbar',
        ORDERS_NAVBAR: 'orders_navbar',
        HOME: 'home',
        SCREEN_RESOLUTION: 'screenResolution',
    };
    public static HOME_PAGE = {
        INCOME: 'income',
        OUTCOME: 'outcome',
        DELTA: 'delta',
        WEEK_STATS: 'weekStats',
        MONTH_STATS: 'monthStats',
        PREV_MONTH: 'prevMonth',
        PREV_WEEKS: 'prevWeeks',
        NEXT_MONTH: 'nextMonth',
        NEXT_WEEKS: 'nextWeeks',
        HOME_PAGE_INFO_ONE: 'home_page_info_one',
        HOME_PAGE_INFO_TWO: 'home_page_info_two',
    };
}
