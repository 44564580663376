import { useEffect, useRef, useState } from 'react';
import { TextField, Autocomplete, Button, ListItem, Box } from '@mui/material';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { IItem } from '../../../../models/Items/IItem';
import { DTO_STATE, IS_DELETED } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getStyles } from '../../recipeCommon';
import { getCommonStyles } from '../../../../common/commonStyles';
import { getMedia } from '../../../../common/mediaQuery';
import { IProportion } from '../../../../models/Recipe/IProportion';

interface CreateRecipeItemsProps {
    itemId: number | string;
    items: IItem[] | undefined;
    setNewItemValues: (recipeItem: IRecipePartItem) => void;
    fillingId: number | string;
    handleDeleteItem: (itemId: number | string) => void;
    setIsNewItemNameEmpty: (data: boolean) => void;
    setIsNewItemCountEmpty: (data: boolean) => void;
    proportions: string;
}

const CreateRecipeItems = ({
    itemId,
    items,
    setNewItemValues,
    fillingId,
    handleDeleteItem,
    setIsNewItemNameEmpty,
    setIsNewItemCountEmpty,
    proportions,
}: CreateRecipeItemsProps) => {
    const { language } = useLanguage();
    const { autoCompleteLabelStyle } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();
    const { isMobile, isTablet } = getMedia();

    const inputCountRef = useRef<HTMLInputElement>(null);
    const idOfItem = itemId || crypto.randomUUID();
    const idOfPart = fillingId || crypto.randomUUID();

    const [newItem, setRecipePartItems] = useState<IRecipePartItem>({
        id: idOfItem,
        itemId: -1,
        itemName: '',
        unitOfWeight: '',
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
        fillingId: idOfPart,
        proportions: [
            {
                id: crypto.randomUUID(),
                quantity: 0,
                proportion: 0,
                state: DTO_STATE.DTO_NEW,
                recipePartItemId: idOfItem,
            },
        ],
    });

    const itemNameList: string[] = [];
    items?.map((item) => itemNameList.push(item.name));

    const handleItemName = (e: any, name: string | null) => {
        const itemName = name || '';
        const itemId = items?.find((item) => item.name === name)?.id || -1;
        const itemUnitOfWeight = items?.find((item) => item.name === name)?.unitOfWeight || '';
        newItem.itemId = itemId;
        newItem.unitOfWeight = itemUnitOfWeight;
        newItem.itemName = itemName;

        refreshItem();
    };

    const handleItemWeight = (e: any) => {
        if (e.target.value > 0) {
            setRecipePartItems({ ...newItem, [e.target.id]: e.target.value });
            newItem.proportions[0].quantity = Number(e.target.value);

            refreshItem();
        }
    };

    const refreshItem = () => {
        setNewItemValues(newItem);
    };

    useEffect(() => {
        setIsNewItemNameEmpty(newItem.itemName === '');
    }, [newItem.itemName]);

    useEffect(() => {
        setIsNewItemCountEmpty(newItem.proportions[0].quantity === 0);
    }, [newItem.proportions[0].quantity, proportions]);

    useEffect(() => {
        setRecipePartItems({
            ...newItem,
            proportions: [{ ...newItem.proportions[0], proportion: Number(proportions) }],
        });

        refreshItem();
    }, [proportions]);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ pt: '10px', mr: '30px', width: '280px' }}>
                <Autocomplete
                    disablePortal
                    id="itemName"
                    placeholder={getTitle(language, 'item_name_recipes')}
                    options={itemNameList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            focused
                            color={newItem.itemName === '' ? 'error' : 'success'}
                            placeholder={getTitle(language, 'item_name_recipes')}
                            required
                            label={getTitle(language, 'item_name_recipes')}
                        />
                    )}
                    onChange={handleItemName}
                    renderOption={(props, option) => (
                        <ListItem {...props} sx={isTablet ? { fontSize: '0.8rem', minHeight: '25px !important' } : {}}>
                            {option}
                        </ListItem>
                    )}
                    sx={{ ...autoCompleteLabelStyle }}
                />
            </Box>

            <Box sx={{ pt: '10px', display: 'flex', ...(isMobile && { width: '280px' }) }}>
                <Box sx={{ ...(newItem.unitOfWeight ? { mr: '15px' } : { mr: '30px' }), display: 'flex' }}>
                    <TextField
                        inputRef={inputCountRef}
                        focused
                        required
                        id="count"
                        label={getTitle(language, 'count_recipes')}
                        placeholder={getTitle(language, 'count_recipes')}
                        type="number"
                        value={newItem.proportions[0].quantity}
                        onChange={handleItemWeight}
                        onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                        color={newItem.proportions[0].quantity === 0 ? 'error' : 'success'}
                    />
                    <Box sx={{ display: 'flex', alignSelf: 'center' }}>{newItem.unitOfWeight ? <>&nbsp;{newItem.unitOfWeight}</> : <></>}</Box>
                </Box>
                <Button
                    variant="outlined"
                    onClick={() => handleDeleteItem(newItem.id)}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: 'rgba(255, 219, 187, 0.25)',
                        fontWeight: '400',
                        margin: 0,
                        alignSelf: 'center',
                        width: 'auto',
                        ...(isMobile
                            ? {
                                  pt: '0px',
                                  pb: '0px',
                                  pr: '0px',
                                  pl: '0px',
                              }
                            : {}),
                    }}
                >
                    {getTitle(language, 'delete_item_part')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateRecipeItems;
