import { TextField, Autocomplete, ListItem, Box } from '@mui/material';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getStyles } from '../../recipeCommon';
import { getMedia } from '../../../../common/mediaQuery';
import { IRecipeLight } from '../../../../models/Recipe/IRecipeLight';
import { IRecipeFilling } from '../../../../models/Recipe/IRecipeFilling';
import { DTO_STATE, FIELDS } from '../../../../common/dictionary';
import { useState, useEffect } from 'react';

interface CreateRecipeItemsProps {
    recipes: IRecipeLight[] | undefined;
    setRecipes: (recipes: IRecipeFilling[]) => void;
    fillingId: string | number;
    fillingName: string | undefined;
    selectedRecipes?: IRecipeFilling[] | undefined;
}

const RecipeRelatives = ({ recipes, setRecipes, fillingId, fillingName, selectedRecipes }: CreateRecipeItemsProps) => {
    const { language } = useLanguage();
    const { autoCompleteLabelStyle } = getStyles();
    const { isTablet } = getMedia();

    const itemsObj: IRecipeFilling[] | undefined = recipes?.map((item) => {
        if (selectedRecipes) {
            const index = selectedRecipes?.findIndex((el) => el.recipeName === item.recipeName);
            if (index >= 0) {
                return selectedRecipes[index];
            }
        }
        return { recipeName: item.recipeName, recipeId: item.id, fillingId: fillingId, fillingName: fillingName, state: DTO_STATE.DTO_NEW };
    });

    const [selectedOptions, setSelectedOptions] = useState<IRecipeFilling[]>(selectedRecipes || []);

    useEffect(() => {
        setSelectedOptions(selectedRecipes || []);
    }, [selectedRecipes]);

    const handleItemName = (e: any, newSelected: IRecipeFilling[]) => {
        const updatedSelection = selectedOptions
            .map((option) => {
                if (!newSelected.some((newItem) => newItem.recipeId === option.recipeId)) {
                    if (option.state !== DTO_STATE.DTO_NEW) {
                        return { ...option, state: DTO_STATE.DTO_DELETED };
                    }
                    return null;
                }
                if (option.state === DTO_STATE.DTO_DELETED) {
                    return { ...option, state: DTO_STATE.DTO_UNCHANGED };
                }
                return option;
            })
            .filter((item): item is IRecipeFilling => item !== null);

        const addedNewItems = newSelected
            .filter((newItem) => !selectedOptions.some((option) => option.recipeId === newItem.recipeId))
            .map((newItem) => ({
                ...newItem,
                state: DTO_STATE.DTO_NEW,
            }));

        const finalSelection = [...updatedSelection, ...addedNewItems];

        setSelectedOptions(finalSelection);
        setRecipes(finalSelection);
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ ml: '10px', pt: '10px', mr: '10px', minWidth: '235px', maxWidth: '1082px' }}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="itemName"
                    placeholder={getTitle(language, FIELDS.RECIPES.RELATIVE_RECIPES)}
                    options={itemsObj || []}
                    getOptionLabel={(option) => option.recipeName ?? ''}
                    value={selectedOptions.filter((option) => option.state !== DTO_STATE.DTO_DELETED)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            focused
                            placeholder={getTitle(language, FIELDS.RECIPES.RELATIVE_RECIPES)}
                            label={getTitle(language, FIELDS.RECIPES.RELATIVE_RECIPES)}
                        />
                    )}
                    onChange={(_, newValue) => handleItemName(_, newValue as IRecipeFilling[])}
                    renderOption={(props, option) => (
                        <ListItem {...props} sx={isTablet ? { fontSize: '0.8rem', minHeight: '25px !important' } : {}}>
                            {option.recipeName}
                        </ListItem>
                    )}
                    isOptionEqualToValue={(option, value) => option.recipeId === value.recipeId}
                    sx={{ ...autoCompleteLabelStyle }}
                />
            </Box>
        </Box>
    );
};

export default RecipeRelatives;
