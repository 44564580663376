import { Box } from '@mui/material';

interface RecipePartItemProps {
    part: any;
    length: number;
    index: number;
}

const FillingRecipe = ({ part, length, index }: RecipePartItemProps) => {
    return (
        <Box component={'span'} sx={{ pr: '10px' }}>
            {part.recipeName}
            {index + 1 !== length && ','}
        </Box>
    );
};

export default FillingRecipe;
