import { SetStateAction, useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { IRecipePart } from '../../../../models/Recipe/IRecipePart';
import CreateRecipeItems from './CreateRecipeItems';
import { IItem } from '../../../../models/Items/IItem';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { DTO_STATE, FIELD_NAMES, IS_DELETED, UNIT_OF_WEIGHT } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getStyles } from '../../recipeCommon';
import { getCommonStyles } from '../../../../common/commonStyles';
import { getMedia } from '../../../../common/mediaQuery';

interface CreateRecipePartProps {
    initPart: IRecipePart;
    items: IItem[] | undefined;
    handleDeletePart: (partId: number | string) => void;
    setIsPartNameEmpty: (data: boolean) => void;
    setIsNewItemNameEmpty: (data: boolean) => void;
    setIsNewItemCountEmpty: (data: boolean) => void;
    proportions: any; // TODO: any
    updatePart: (part: IRecipePart) => void;
    existPartName: boolean;
}

const CreateRecipePart = ({
    initPart,
    items,
    handleDeletePart,
    setIsPartNameEmpty,
    setIsNewItemNameEmpty,
    setIsNewItemCountEmpty,
    proportions,
    updatePart,
    existPartName,
}: CreateRecipePartProps) => {
    const { language } = useLanguage();
    const { autoCompleteLabelStyle } = getStyles();
    const { isMobile } = getMedia();
    const { simpleBtnStyle } = getCommonStyles();

    const [part, setPart] = useState<IRecipePart>({
        ...initPart,
        items: [
            ...(initPart.items || []),
            {
                id: crypto.randomUUID(),
                itemId: -1,
                recipePartId: initPart.id,
                itemName: '',
                unitOfWeight: UNIT_OF_WEIGHT.G,
                isDeleted: IS_DELETED.ACTIVE,
                state: DTO_STATE.DTO_NEW,
                proportions: [],
                fillingId: 0,
            },
        ],
    });

    const [newItems, setRecipePartItems] = useState<IRecipePartItem[]>([
        {
            id: crypto.randomUUID(),
            itemId: -1,
            recipePartId: part.id,
            itemName: '',
            unitOfWeight: UNIT_OF_WEIGHT.G,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            proportions: [],
            fillingId: 0,
        },
    ]);

    const addItem = () => {
        setPart((prev) => {
            return {
                ...prev,
                items: [
                    ...(prev.items || []),
                    {
                        id: crypto.randomUUID(),
                        itemId: -1,
                        recipePartId: part.id,
                        itemName: '',
                        unitOfWeight: UNIT_OF_WEIGHT.G,
                        isDeleted: IS_DELETED.ACTIVE,
                        state: DTO_STATE.DTO_NEW,
                        proportions: [],
                        fillingId: 0,
                    },
                ],
            };
        });
    };

    const setNewItemValues = (recipeItem: IRecipePartItem) => {
        if (part.items) {
            console.log(part.items);
            const temp = part.items.map((el) => {
                if (el.id === recipeItem.id) {
                    return recipeItem;
                }
                return el;
            });
            console.log(temp);
            console.log(recipeItem);

            setPart((prev) => {
                return {
                    ...prev,
                    items: [...temp],
                };
            });
        }
    };

    const insertItem = (arr: any, newItem: any) => {
        const index = arr.findIndex((item: any) => item.id === newItem.id);

        if (index !== -1) {
            arr[index] = newItem;
        } else {
            arr.push(newItem);
        }

        return arr;
    };

    const setPartName = (e: { target: { value: SetStateAction<string> } }) => {
        setPart((prev) => {
            return {
                ...prev,
                partName: e.target.value as string,
            };
        });
    };

    const handleDeleteItem = (itemId: number | string) => {
        if (part.items) {
            const index = part.items.findIndex((item) => item.id === itemId);
            const temp = part.items;
            temp.splice(index, 1);
            setPart((prev) => {
                return {
                    ...prev,
                    items: [...temp],
                };
            });
        }
    };

    useEffect(() => {
        updatePart(part);
    }, [part]);

    return (
        <Box
            sx={{
                margin: '10px',
                padding: '10px',
                backgroundColor: '#fcfdf5',
                borderTop: '2px solid rgba(0, 0, 0, 0.1)',
                borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    ...(isMobile ? { width: '280px' } : {}),
                }}
            >
                <TextField
                    focused
                    required
                    id={FIELD_NAMES.NAME}
                    label={getTitle(language, 'part_name')}
                    placeholder={getTitle(language, 'part_name')}
                    value={part.partName}
                    onChange={setPartName}
                    color={part.partName === '' || existPartName ? 'error' : 'success'}
                    sx={{ ...autoCompleteLabelStyle, width: '280px', mr: '30px', backgroundColor: existPartName ? 'wheat' : '' }}
                />

                <Button
                    variant="outlined"
                    onClick={() => handleDeletePart(part.id)}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: 'rgba(255, 219, 187, 0.25)',
                        fontWeight: '400',
                        margin: 0,
                        alignSelf: 'center',
                        ...(isMobile
                            ? {
                                  pt: '0px',
                                  pb: '0px',
                                  pr: '5px',
                                  pl: '5px',
                              }
                            : {}),
                    }}
                >
                    {getTitle(language, 'delete_part')}
                </Button>
            </Box>

            {/* items for the part */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {part.items?.map((item) => (
                    <CreateRecipeItems
                        key={item.id}
                        itemId={item.id}
                        items={items}
                        setNewItemValues={setNewItemValues}
                        partId={part.id}
                        handleDeleteItem={handleDeleteItem}
                        setIsNewItemNameEmpty={setIsNewItemNameEmpty}
                        setIsNewItemCountEmpty={setIsNewItemCountEmpty}
                        proportions={proportions}
                        existItemName={part.items?.some((el) => el.id !== item.id && el.itemName?.toLowerCase() === item.itemName?.toLowerCase()) || false}
                    />
                ))}
            </Box>

            <Box sx={{ pt: '10px' }}>
                <Button
                    variant="outlined"
                    onClick={addItem}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: '',
                        fontWeight: '400',
                        margin: 0,
                        padding: '5px 0',
                        width: '253px',
                    }}
                >
                    {getTitle(language, 'add_new_item')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateRecipePart;
