import { useEffect, useState } from 'react';
import { Box, LinearProgress, Stack, Divider, Button } from '@mui/material';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import { EnhancedCollapsibleTable } from './tableFillings/EnhancedCollapsibleTable';
import { getStyles } from './recipeCommon';
import { fetchFillings } from './dataManager';
import { getMedia } from '../../common/mediaQuery';
import CreateFilling from './filling/createFilling/CreateFilling';
import { IFilling } from '../../models/Recipe/Filling/IFilling';
import { FIELDS } from '../../common/dictionary';

function Fillings() {
    const { language } = useLanguage();
    const { isFullHD } = getMedia();
    const { funcButtonWrapper, wrapperItems, boxItemsLoading, buttonItemsStyle, rowsPP } = getStyles();

    const [createFilling, setCreateFilling] = useState(false);
    const [isNewDataFetching, setIsNewDataFetching] = useState(false);
    const [isAllFillingsIsLoading, setIsAllFillingsIsLoading] = useState(false);

    // api data state
    const [fillingsData, setFillingsData] = useState<IFilling[]>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(rowsPP);
    const [itemsQtyTotal, setItemsQtyTotal] = useState(1);

    // search state
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [orderByName, setOrderByName] = useState(false);
    const [descending, setDescending] = useState(false);

    const handleOrderByName = () => {
        if (!orderByName) {
            setOrderByName(true);
        } else {
            setDescending(!descending);
        }
    };

    const CATEGORIES = '';
    const ORDER_BY_COST = false;

    const { fillings, getAllFillingsIsLoading, getAllFillingsError, getAllFillingsIsSuccess } = fetchFillings({
        currentPageNumber,
        itemsPerPage,
        searchPhrase,
        categories: CATEGORIES,
        descending,
        orderByName,
        orderByCost: ORDER_BY_COST,
    });

    useEffect(() => {
        if (fillings) {
            setFillingsData(fillings.data);

            setCurrentPageNumber(fillings.page);
            setItemsPerPage(fillings.pageSize);
            setItemsQtyTotal(fillings.totalCount);

            setIsNewDataFetching(false);

            setIsAllFillingsIsLoading(!getAllFillingsIsSuccess);
        }
    }, [fillings]);

    return (
        <Box sx={{ ...wrapperItems, pt: 0, ...(isFullHD ? { minHeight: '830px' } : {}) }}>
            <Box sx={{ ...funcButtonWrapper, mt: '20px', mb: '5px' }}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'center'} gap={'5px'}>
                    <Button
                        variant="outlined"
                        onClick={() => setCreateFilling(!createFilling)}
                        sx={{
                            ...buttonItemsStyle,
                            margin: '5px',
                            boxShadow: '0px 1px 3px #999',
                            fontWeight: '600',
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                        }}
                    >
                        {createFilling ? `${getTitle(language, FIELDS.RECIPES.BACK_TO_FILLINGS)}` : `${getTitle(language, FIELDS.RECIPES.CREATE_FILLING)}`}
                    </Button>
                </Stack>
            </Box>

            {(getAllFillingsIsLoading || isAllFillingsIsLoading) && (
                <Box sx={boxItemsLoading}>
                    <LinearProgress />
                </Box>
            )}

            {createFilling && <CreateFilling setCreateFilling={setCreateFilling} setIsAllFillingsIsLoading={setIsAllFillingsIsLoading} />}

            {!createFilling && getAllFillingsIsSuccess && !getAllFillingsIsLoading && !getAllFillingsError && (
                <EnhancedCollapsibleTable
                    items={fillingsData}
                    itemsQtyTotal={itemsQtyTotal}
                    itemsPerPage={itemsPerPage}
                    currentPageNumber={currentPageNumber}
                    setItemsPerPage={setItemsPerPage}
                    setCurrentPageNumber={setCurrentPageNumber}
                    isNewDataFetching={isNewDataFetching}
                    setIsNewDataFetching={setIsNewDataFetching}
                    setSearchPhrase={setSearchPhrase}
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                    setOrderByName={handleOrderByName}
                    orderByName={orderByName}
                    setIsAllFillingsIsLoading={setIsAllFillingsIsLoading}
                />
            )}
        </Box>
    );
}

export default Fillings;
