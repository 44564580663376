import { useEffect, useState } from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { DTO_STATE, FIELDS, IS_DELETED } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getStyles } from '../../recipeCommon';
import { getAllItems, saveFillingPost } from '../../dataManager';
import { getMedia } from '../../../../common/mediaQuery';
import { IFilling } from '../../../../models/Recipe/Filling/IFilling';
import { EditFillingItem } from './EditFillingItem';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { IRecipeLight } from '../../../../models/Recipe/IRecipeLight';
import RecipeRelatives from '../createFilling/RecipeRelatives';
import { IRecipeFilling } from '../../../../models/Recipe/IRecipeFilling';

interface Props {
    recipes: IRecipeLight[] | undefined;
    fillingData: IFilling;
    handleClickEdit: (id: string | number) => Promise<void>;
    size: string;
}

export const EditFilling = ({ recipes, fillingData, handleClickEdit, size }: Props) => {
    const { partsInputStyle, opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();
    const { language } = useLanguage();
    const { innerWidth: width } = window;

    // api
    const { items, getAllItemsError, getAllItemsIsLoading, getAllItemsIsSuccess } = getAllItems();
    const { saveFilling, saveFillingError, isSaveFillingSuccess } = saveFillingPost();

    // data
    const [editedFilling, setEditedFilling] = useState<IFilling>({ ...fillingData });
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const hasNewState = hasStateNew(editedFilling.recipes || []) || hasStateNew(editedFilling.items || []);
    const isHasChanged = !compareObjects(fillingData, editedFilling);
    const [bgcN, setBgcN] = useState('transparent');
    const [bgcD, setBgcD] = useState('transparent');
    const [isLoadingOnSave, setIsLoadingOnSave] = useState(false);

    // methods
    const changeRecipeName = (e: any) => {
        const newFillingName = e.target.value;
        setEditedFilling((prevState) => ({
            ...prevState,
            fillingName: newFillingName,
            state: DTO_STATE.DTO_MODIFIED,
        }));

        if (fillingData?.fillingName !== newFillingName) {
            setBgcN('rgba(233, 255, 77, 0.5)');
        } else {
            setBgcN('transparent');
        }
    };

    const changeRecipeDescription = (e: any) => {
        const newDescription = e.target.value;

        setEditedFilling((prevState) => ({
            ...prevState,
            fillingDescription: newDescription,
            state: DTO_STATE.DTO_MODIFIED,
        }));

        if (fillingData?.fillingDescription !== newDescription) {
            setBgcD('rgba(233, 255, 77, 0.5)');
        } else {
            setBgcD('transparent');
        }
    };

    function hasStateNew(objects: any[]): boolean {
        return objects.some((item) => item.state === DTO_STATE.DTO_NEW || item.state === DTO_STATE.DTO_DELETED);
    }

    function compareObjects(obj1: any, obj2: any): boolean {
        for (const key in obj1) {
            if (key === 'state') continue;
            if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                if (!compareObjects(obj1[key], obj2[key])) {
                    return false;
                }
            } else if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }

    function findIndicesOfDuplicates(items: IRecipePartItem[] | undefined) {
        const nameMap: { [name: string]: number[] } = {};
        const duplicateIndices: number[] = [];

        // don't save new item which was deleted
        const index = (items && items.findIndex((item: IRecipePartItem) => item.state === DTO_STATE.DTO_DELETED && typeof item.id === 'string')) || -1;

        if (index !== -1) {
            items && items.splice(index, 1);
        }

        // Create a map of names to their indices
        items &&
            items.forEach((object: IRecipePartItem, index: number) => {
                const { itemName } = object;
                if (itemName in nameMap) {
                    nameMap[itemName].push(index);
                } else {
                    nameMap[itemName] = [index];
                }
            });

        // Find indices of names with more than one occurrence
        for (const name in nameMap) {
            if (nameMap[name].length > 1) {
                duplicateIndices.push(...nameMap[name]);
            }
        }

        return duplicateIndices;
    }

    const handleEditItem = (editedItem: IRecipePartItem | undefined) => {
        if (!!editedItem) {
            if (!!editedFilling.items) {
                let newItems: IRecipePartItem[] | undefined = [];
                editedFilling.items.forEach((item) => {
                    if (item.id !== editedItem.id) {
                        newItems?.push(item);
                    } else {
                        if (typeof editedItem.id === 'string' && editedItem.state === DTO_STATE.DTO_DELETED) {
                            return;
                        }
                        newItems?.push(editedItem);
                    }
                });
                setEditedFilling((prev) => {
                    return {
                        ...prev,
                        items: [...(newItems || [])],
                        state: DTO_STATE.DTO_MODIFIED,
                    };
                });
            }
        }
    };

    const handleRelativeRecipe = (recipes: IRecipeFilling[]) => {
        setEditedFilling((prev) => {
            return {
                ...prev,
                recipes: [...recipes],
                state: DTO_STATE.DTO_MODIFIED,
            };
        });
    };

    const addNewItem = () => {
        const tempId = crypto.randomUUID();
        const newItem: IRecipePartItem = {
            id: tempId,
            itemId: -1,
            itemName: '',
            unitOfWeight: '',
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            fillingId: editedFilling.id,
            proportions: [
                {
                    id: crypto.randomUUID(),
                    proportion: Number(size),
                    quantity: 0,
                    recipePartItemId: tempId,
                    state: DTO_STATE.DTO_NEW,
                },
            ],
        };

        const newItems: IRecipePartItem[] | undefined = editedFilling.items?.map((item) => item);
        newItems?.push(newItem);
        setEditedFilling((prev) => {
            return {
                ...prev,
                items: [...(newItems || [])],
                state: DTO_STATE.DTO_MODIFIED,
            };
        });
    };

    const handleSaveFilling = () => {
        editedFilling.state = DTO_STATE.DTO_MODIFIED;
        setIsLoadingOnSave(true);

        // don't save new item which was deleted
        editedFilling && editedFilling.items && editedFilling.items.filter((item) => !(item.state === DTO_STATE.DTO_DELETED && typeof item.id === 'string'));

        saveFilling(editedFilling as IFilling).then(() => {
            handleClickEdit(fillingData.id);
            setIsLoadingOnSave(false);
        });
    };

    useEffect(() => {
        setIsSaveDisabled(!(isHasChanged || hasNewState));
    }, [isHasChanged, hasNewState]);

    return (
        <>
            <Button
                sx={{ position: 'absolute', top: '-36px', ...(isTablet ? { left: `${width / 2.8}px`, fontSize: '0.8rem' } : { right: 0 }) }}
                onClick={handleSaveFilling}
                disabled={isSaveDisabled}
            >
                {getTitle(language, FIELDS.RECIPES.SAVE_FILLING)}
            </Button>

            <Grid item xs={12} sx={{ mt: '5px', mb: '20px', position: 'relative' }}>
                <Paper sx={{ boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.2)' }}>
                    <Table sx={{ backgroundColor: '#fafaf8' }}>
                        <TableBody>
                            <TableRow
                                sx={{
                                    height: '55px',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                        pl: '10px',
                                    }}
                                >
                                    <TextField
                                        size={'small'}
                                        label={getTitle(language, FIELDS.RECIPES.FILLING_NAME)}
                                        required
                                        value={editedFilling.fillingName}
                                        onChange={changeRecipeName}
                                        variant={'filled'}
                                        sx={{
                                            ...partsInputStyle,
                                            backgroundColor: bgcN,
                                            ...(isTablet
                                                ? {
                                                      '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                                      width: '170px',
                                                  }
                                                : {}),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    height: '55px',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                        pl: '10px',
                                    }}
                                >
                                    <TextField
                                        size={'small'}
                                        label={getTitle(language, FIELDS.RECIPES.DESCRIPTION)}
                                        value={editedFilling.fillingDescription}
                                        onChange={changeRecipeDescription}
                                        variant={'filled'}
                                        sx={{
                                            ...partsInputStyle,
                                            backgroundColor: bgcD,
                                            ...(isTablet
                                                ? {
                                                      '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                                      width: '170px',
                                                  }
                                                : {}),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    position: 'absolute',
                                    backgroundColor: '#fafaf8',
                                    pl: '15px',
                                    pr: '15px',
                                    top: '37px',
                                    ...(isTablet ? { right: '5px' } : { right: '27px' }),
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={addNewItem}
                                        sx={{
                                            bgcolor: '#fff',
                                            ...(isTablet
                                                ? {
                                                      fontSize: '0.8rem !important',
                                                  }
                                                : {}),
                                        }}
                                    >
                                        {isTablet ? <AddToPhotosIcon /> : getTitle(language, FIELDS.RECIPES.ADD_NEW_ITEM)}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            {editedFilling.items?.map((item, index) => {
                                const isDuplicateName = findIndicesOfDuplicates(editedFilling.items).includes(index);

                                return (
                                    <TableRow
                                        key={item.id}
                                        sx={{
                                            height: '55px',
                                            backgroundColor: item.state === DTO_STATE.DTO_NEW ? 'rgba(233, 255, 77, 0.3)' : 'transparent',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                padding: '0px',
                                                pl: '10px',
                                                pb: '0.5px',
                                            }}
                                        >
                                            <EditFillingItem
                                                key={item.id}
                                                item={item}
                                                items={items}
                                                isLast={editedFilling.items?.length !== index + 1}
                                                saveChanges={handleEditItem}
                                                isDuplicateName={isDuplicateName}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                        pl: '10px',
                                        pb: '0.5px',
                                    }}
                                >
                                    <RecipeRelatives
                                        recipes={recipes}
                                        setRecipes={handleRelativeRecipe}
                                        fillingId={editedFilling.id}
                                        fillingName={editedFilling.fillingName}
                                        selectedRecipes={editedFilling.recipes}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </>
    );
};
