import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getStyles } from '../recipeCommon';
import { getMedia } from '../../../common/mediaQuery';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import { IFilling } from '../../../models/Recipe/Filling/IFilling';
import { fillingAPI } from '../../../services/FillingServices';

interface EnhancedTableToolbarProps {
    numSelected: number;
    searchInput: string;
    handleChangeSearch: (value: string) => void;
    selected: number[];
    setSelected: (ids: number[]) => void;
    setIsAllFillingsIsLoading: (arg: boolean) => void;
    items: IFilling[];
}

export function EnhancedTableToolbar({ numSelected, searchInput, handleChangeSearch, selected, setSelected, setIsAllFillingsIsLoading, items }: EnhancedTableToolbarProps) {
    const { language } = useLanguage();
    const { toolbarWrapper, inputStyle, titleStyle } = getStyles(numSelected);
    const { isTablet } = getMedia();

    const [deleteFillings, { error: deleteFillingsError, isLoading: deletingInProcess, isSuccess: deleteFillingsSuccess }] = fillingAPI.useDeleteFillingsMutation();

    const [isFocus, setIsFocus] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [deleteItemsNames, setDeleteItemsNames] = useState<string[]>([]);

    const openDialog = () => {
        setIsDialogOpen(true);
        items.forEach((el) => selected.includes(Number(el.id)) && setDeleteItemsNames((prev) => (prev !== undefined ? [...prev, el.fillingName || ''] : [el.fillingName || ''])));
    };

    const closeDialog = () => {
        setIsDialogOpen(false);

        setDeleteItemsNames([]);
    };

    const handleConfirmDelete = () => {
        setIsAllFillingsIsLoading(true);
        deleteFillings(selected);
        setSelected([]);
        closeDialog();
    };

    return (
        <Toolbar sx={toolbarWrapper}>
            <TextField
                autoFocus={searchInput.length > 0 && isFocus}
                id={'searchInput'}
                label={getTitle(language, 'search')}
                variant="standard"
                value={searchInput}
                onChange={(e) => handleChangeSearch(e.target.value)}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                sx={{ ...inputStyle, '.MuiInputBase-input': { pr: '0px' } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchInput && (
                                <IconButton aria-label="clear search" onClick={(e) => handleChangeSearch(CLEAR_BUTTON)} edge="end" sx={{ mr: '0px', ml: '0px' }}>
                                    <SearchOffIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />

            <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
                <DialogTitle id="confirm-dialog-title">{getTitle(language, 'confirm_deletion')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {getTitle(language, 'delete_title')}&nbsp;{deleteItemsNames.join(', ')}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        {getTitle(language, 'cancel')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        {getTitle(language, 'confirm')}
                    </Button>
                </DialogActions>
            </Dialog>

            {!isTablet ? (
                numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1" component="div" sx={titleStyle}>
                        {numSelected} {getTitle(language, 'numSelected')}
                    </Typography>
                ) : null
            ) : null}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={openDialog}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </Toolbar>
    );
}
