import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { getStyles } from '../../recipeCommon';
import { getFilling, getRecipe, getRecipesLight } from '../../dataManager';
import { getMedia } from '../../../../common/mediaQuery';
import { IFilling } from '../../../../models/Recipe/Filling/IFilling';
import { EditFilling } from './EditFilling';

interface Props {
    filling: IFilling;
    setIsLoading: (arg0: boolean) => void;
    handleClickEdit: (id: string | number) => Promise<void>;
    isLoading: boolean;
}

export const EditFillingHOC = ({ filling, setIsLoading, handleClickEdit, isLoading }: Props) => {
    const { opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();

    // api
    const { fillingData, isLoadingFilling, getFillingError, isSuccessFilling } = getFilling(filling.id);
    const { recipes, isLoadingRecipe, getRecipeError, isSuccessRecipe } = getRecipesLight();
    const key_id = `${fillingData?.fillingName || fillingData?.createdAt}-${fillingData?.id}`;

    // effects
    useEffect(() => {
        if (isSuccessFilling) {
            setIsLoading(false);
        }
    }, [isSuccessFilling]);

    return (
        <Box
            sx={{
                flexBasis: isTablet ? '100%' : '59%',
                position: 'relative',
                ...(isLoadingFilling ? opacityHalf : isSuccessFilling ? opacityFull : opacityHalf),
            }}
        >
            {isLoadingFilling ? (
                <Box sx={{ position: 'absolute', top: '40%', left: '-5%', zIndex: 99 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <></>
            )}
            {fillingData && !isLoadingFilling && <EditFilling key={key_id} recipes={recipes} fillingData={fillingData} size={'1.00'} handleClickEdit={handleClickEdit} />}
        </Box>
    );
};
