import { DTO_STATE } from '../common/dictionary';
import { IRecipe } from '../models/Recipe/IRecipe';
import { IRecipeLight } from '../models/Recipe/IRecipeLight';
import { IRecipePart } from '../models/Recipe/IRecipePart';
import { IRecipePartItem } from '../models/Recipe/IRecipePartItem';
import { IRecipeValidator } from './interfaces/IRecipeValidator';

export class RecipeValidator implements IRecipeValidator {
    private recipe: IRecipe;
    private recipes: IRecipeLight[];
    private static instance: RecipeValidator;

    constructor(recipe: IRecipe, recipes: IRecipeLight[]) {
        this.recipe = recipe;
        this.recipes = recipes;
    }

    private setObjects(recipe: IRecipe, recipes: IRecipeLight[]) {
        this.recipe = recipe;
        this.recipes = recipes;
    }

    public static getInstance(recipe: IRecipe, recipes: IRecipeLight[]): IRecipeValidator {
        if (!RecipeValidator.instance) {
            RecipeValidator.instance = new RecipeValidator(recipe, recipes);
        } else {
            RecipeValidator.instance.setObjects(recipe, recipes);
        }

        return RecipeValidator.instance;
    }

    public isValidParts(parts?: IRecipePart[]): boolean {
        let isValid = true;
        const p = parts || this.recipe.parts;

        p?.forEach((el) => {
            if (!isValid) {
                return;
            }

            if (isValid && (el.state === DTO_STATE.DTO_UNCHANGED || el.state === DTO_STATE.DTO_DELETED)) {
                isValid = false;
            }

            if (isValid && p.some((part) => part.id !== el.id && part.partName?.toLowerCase() === el.partName?.toLowerCase())) {
                isValid = false;
            }

            if ((isValid && !el.partName) || el.partName?.length === 0) {
                isValid = false;
            }

            if (isValid) {
                isValid = this.isValidPartItems(el.items);
            }
        });

        return isValid;
    }

    private isValidPartItems(items?: IRecipePartItem[]): boolean {
        let isValid = true;

        items?.forEach((el) => {
            if (!isValid) {
                return;
            }

            if (isValid && (el.state === DTO_STATE.DTO_UNCHANGED || el.state === DTO_STATE.DTO_DELETED)) {
                isValid = false;
            }

            if (isValid && items.some((item) => item.id !== el.id && item.itemName?.toLowerCase() === el.itemName?.toLowerCase())) {
                isValid = false;
            }

            if (isValid && el.itemName.length === 0) {
                isValid = false;
            }

            if (isValid && el.itemId === 0) {
                isValid = false;
            }

            if (isValid && el.proportions.some((p) => p.quantity === 0)) {
                isValid = false;
            }
        });

        return isValid;
    }

    public isValidRecipeFields(recipe?: IRecipe): boolean {
        let isValid = true;
        const r = recipe || this.recipe;

        if (this.recipes.some((x) => x.recipeName.toLowerCase() === r.recipeName?.toLowerCase())) {
            isValid = false;
        }
        if (r.recipeName?.length === 0 || r.recipeName?.replaceAll(' ', '').length === 0) {
            isValid = false;
        }

        return isValid;
    }

    public validate(disableConsole?: boolean): boolean {
        const results: boolean[] = [this.isValidRecipeFields(), this.isValidParts()];
        const isValidRecipe = results.every((el) => el === true);

        if (process.env.NODE_ENV !== 'production' && !disableConsole) {
            console.log('Is valid recipe fields: ' + results[0]);
            console.log('Is valid parts: ' + results[1]);
            console.log('Is valid recipe: ' + isValidRecipe);
        }

        return isValidRecipe;
    }
}
