import { IItem } from '../models/Items/IItem';
import { ENDPOINTS } from '../common/endpoints';
import { baseAPI } from './BaseAPI';

// TODO: move types to modals folder
type SearchPropsType = {
    page: number;
    limit: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCount: boolean;
    orderByPrice: boolean;
    orderByWeight: boolean;
};

type SearchResponseType = {
    data: IItem[];
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
};

export const itemAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllItems: build.query<IItem[], void>({
            query: () => ({
                url: ENDPOINTS.ITEMS.GET_ALL_ITEMS,
                method: 'GET',
            }),

            providesTags: ['Item', { type: 'Item', id: 'LIST' }],
        }),

        getItemsByPages: build.query<SearchResponseType, SearchPropsType>({
            query: ({ page, limit, searchPhrase, categories, descending, orderByName, orderByCount, orderByPrice, orderByWeight }: SearchPropsType) => ({
                url: `${ENDPOINTS.ITEMS.GET_ITEMS}?page=${page}&limit=${limit}&searchPhrase=${searchPhrase}&categories=${categories}&descending=${descending}&orderByName=${orderByName}&orderByCount=${orderByCount}&orderByPrice=${orderByPrice}&orderByWeight=${orderByWeight}`,
                method: 'GET',
            }),
            providesTags: (result) => ['Item'],
        }),

        getItemsByCategory: build.query<IItem[], string[]>({
            query: (categories: string[]) => ({
                url: ENDPOINTS.ITEMS.GET_ITEMS_BY_CATEGORIES,
                method: 'GET',
                params: {
                    categories: [...categories],
                },
            }),

            providesTags: (result) => ['Item'],
        }),

        getItemById: build.query<IItem, number>({
            query: (id: number) => ({
                url: `${ENDPOINTS.ITEMS.GET_ITEM_BY_ID}${id}`,
                method: 'GET',
            }),
            providesTags: (result) => ['Item'],
        }),

        createItem: build.mutation<IItem, Partial<IItem>>({
            query: (newItem: IItem) => ({
                url: ENDPOINTS.ITEMS.CREATE_ITEM,
                method: 'POST',
                body: newItem,
            }),

            invalidatesTags: ['Item'],
        }),

        deleteItem: build.mutation<IItem, number | undefined>({
            query: (id: number) => ({
                url: `${ENDPOINTS.ITEMS.DELETE_ITEM}${id}`,
                method: 'DELETE',
            }),

            invalidatesTags: ['Item'],
        }),

        addItemCategory: build.mutation<IItem, IItem>({
            query: (item: IItem) => ({
                url: ENDPOINTS.ITEMS.ADD_ITEM_CATEGORY,
                method: 'POST',
                body: item,
            }),

            invalidatesTags: ['Item'],
        }),

        renameItem: build.mutation<IItem, [number, string]>({
            query: (data: [number, string]) => ({
                url: ENDPOINTS.ITEMS.RENAME_ITEM,
                method: 'POST',
                params: {
                    itemId: data[0],
                    newName: data[1],
                },
            }),

            invalidatesTags: ['Item'],
        }),
    }),
});
