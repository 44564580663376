import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Collapse, Dialog, Typography } from '@mui/material';
import { getCommonStyles } from '../../../common/commonStyles';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import RecipeInSubOrder from './RecipeInSubOrder';
import { FIELDS } from '../../../common/dictionary';
import { IOrderImage } from '../../../models/Order/IOrderImage';

interface Image extends IOrderImage {
    recipeName: string;
    url: string;
}

interface SubOrderProps {
    subOrder: ISubOrder;
    imageUrl: Image[];
}

const SubOrder = ({ subOrder, imageUrl }: SubOrderProps) => {
    const { language } = useLanguage();
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();
    const [showRecipe, setShowRecipe] = useState(false);
    const [color, setColor] = useState('white');
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleShowRecipe = () => {
        setShowRecipe(!showRecipe);
    };

    const handleClickOpen = (image: string) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage('');
    };

    return (
        <Box sx={{ padding: '5px', backgroundColor: color, boxShadow: '0px 1px 3px #999' }}>
            <Box>
                {getTitle(language, FIELDS.ORDERS.RECIPE_TITLE)}
                {subOrder.recipeName}
            </Box>
            {subOrder.fillingId !== 0 && (
                <Box>
                    {getTitle(language, FIELDS.ORDERS.FILLING_TITLE)}
                    {subOrder.fillingName}
                </Box>
            )}
            <Box>
                {getTitle(language, FIELDS.ORDERS.DESCRIPTION_ORDERS)}
                {subOrder.description}
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                {subOrder.price}zł
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.ORDER_COST)}
                {subOrder.productCost}zł
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.COUNT_ORDERS)}:&nbsp;
                {subOrder.count}
            </Box>
            <Box>
                {getTitle(language, FIELDS.ORDERS.RECIPE_SIZE_KG)}:&nbsp;{subOrder.recipeCounter}
            </Box>

            {imageUrl.map((image, index) => (
                <Card key={index} sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={image.url}
                        alt={subOrder.recipeName}
                        sx={{ objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => handleClickOpen(image.url)}
                    />
                </Card>
            ))}
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia component="img" image={selectedImage} alt={subOrder.recipeName} sx={{ objectFit: 'contain' }} />
                    <CardContent>
                        <Typography variant="body1" component="div">
                            {subOrder.recipeName}
                        </Typography>
                        <Button onClick={handleClose} color="primary">
                            Закрыть
                        </Button>
                    </CardContent>
                </Card>
            </Dialog>

            <Button
                variant="outlined"
                onClick={handleShowRecipe}
                sx={{
                    ...buttonStyle,
                    boxShadow: '0px 1px 3px #999',
                    fontWeight: '600',
                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                }}
            >
                {getTitle(language, FIELDS.RECIPES.RECIPE)}
            </Button>

            <Collapse in={showRecipe} timeout="auto" unmountOnExit>
                <RecipeInSubOrder key={subOrder.recipeId} recipeId={subOrder.recipeId} size={subOrder.recipeCounter} fillingId={subOrder.fillingId} />
            </Collapse>
        </Box>
    );
};

export default SubOrder;
