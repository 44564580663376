import { Box, Grid, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getMedia } from '../../../common/mediaQuery';
import { getStyles } from '../recipeCommon';
import FillingRecipe from './FillingRecipe';
import FillingPartItem from './FillingPartItem';
import { IFilling } from '../../../models/Recipe/Filling/IFilling';
import { FIELDS } from '../../../common/dictionary';

interface RecipeProps {
    filling: IFilling;
    openedRow: boolean;
    isLoading: boolean;
    size: number;
    viewInRecipe: boolean;
}

const Filling = ({ filling, openedRow, isLoading, size, viewInRecipe }: RecipeProps) => {
    const { language } = useLanguage();
    const { opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();

    return (
        <Box sx={{ flexBasis: isTablet ? '100%' : '39%', position: 'relative' }}>
            <Box sx={isLoading ? opacityHalf : opacityFull}>
                <Grid item xs={12} sx={{ mt: '5px', mb: '20px' }}>
                    <Paper sx={{ boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.2)' }}>
                        <Table sx={{ backgroundColor: '#fafaf8' }}>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, FIELDS.RECIPES.FILLING_NAME)}: &nbsp;
                                        {filling.fillingName}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, FIELDS.RECIPES.DESCRIPTION)}: &nbsp;
                                        {filling.fillingDescription}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: openedRow && !isLoading ? '0px' : '10px',
                                            pb: openedRow && !isLoading ? '0px' : '10px',
                                        }}
                                    >
                                        <Box sx={{ mb: '10px' }}>{getTitle(language, FIELDS.RECIPES.FILLING_NAME)}: &nbsp;</Box>
                                        {filling.items?.map((part: any, index: number) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    ...(part && part.length > index + 1 && { borderBottom: '0.1px solid rgba(0,0,0,0.1)' }),
                                                    height: openedRow && !isLoading ? '55px' : '',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    pt: 0,
                                                    pb: 0,
                                                }}
                                            >
                                                <FillingPartItem key={index} recipePart={part} size={size} />
                                            </Box>
                                        ))}
                                    </TableCell>
                                </TableRow>
                                {!viewInRecipe && (
                                    <TableRow>
                                        <TableCell sx={{ pl: '16px', pt: '10px', pb: '10px' }}>
                                            {getTitle(language, FIELDS.RECIPES.USED_IN_RECIPES)}:
                                            <Box sx={{ pl: '10px', pt: '10px' }}>
                                                {filling.recipes?.map((part: any, index: number) => (
                                                    <FillingRecipe key={index} part={part} length={filling.recipes.length} index={index} />
                                                ))}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Box>
        </Box>
    );
};
export default Filling;
